import React, { memo, useRef } from 'react';
import Card from "@mui/material/Card";
import PPBox from "components/PPBox";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import { useState, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import deLocale from '@fullcalendar/core/locales/de';
import { getRequestOptions, getCurrentUser, getCurrentUserId, userHasAccess, ROLES, setPageTitle } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import ManageEvent from "./components/ManageEvent";
import DocumentationDialog from "pages/patients/components/Documentation/DocumentationDialog";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import interactionPlugin from "@fullcalendar/interaction";
import Select from '@mui/material/Select';
import { Menu, MenuItem } from '@mui/material';
import Spinner from "controls/Spinner";
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Calendar = memo((props) => {
    const { t } = useTranslation();
    setPageTitle(t('calendar'));
    const calendarRef = useRef(null);
    const [users, setUsers] = useState([]);
    const eventsRef = useRef([]);
    const [refreshCalendar, setRefreshCalendar] = useState(false);
    const [pageState, setPageState] = useState({
        isLoading: false,
        events: [],
        totalRecords: 0,
        currentPage: 1,
        pageSize: 6,
        selectedEvent: null,
        selectedPatientIds: [],
        selectedUserId: getCurrentUserId(),
        hideLoadMore: false,
        hiddenDaysInCalendar: getCurrentUser().hiddenDaysInCalendar,
        homeVisitAddTime: 0,
    });
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [openEventDialog, setOpenEventDialog] = useState(false);
    const [openDocumentationDialog, setOpenDocumentationDialog] = useState(false);

    const handleAddEventDialog = (e) => {
        e.preventDefault();
        setPageState(old => ({ ...old, selectedEvent: null }));
        setOpenEventDialog(true);
    };

    const handleCloseEventDialog = () => {
        setOpenEventDialog(false);
        setPageState(old => ({ ...old, selectedEvent: null }));
    };

    const handleEventClick = (e) => {
        if (!e.event.allDay) {
            setPageState(old => ({ ...old, selectedEvent: e.event, selectedPatientIds: e.event.extendedProps.patientIds }));
            setMenuAnchor(e.el);
        }
    }

    const handleDateClick = (e) => {
        const formData = {
            start: moment(e.dateStr).format("yyyy-MM-DD HH:mm")
        };
        setPageState(old => ({ ...old, selectedEvent: formData }));
        setOpenEventDialog(true);
    }

    const handleUpdateStatus = (newStatus) => {
        var body = JSON.stringify({
            "eventId": pageState.selectedEvent.id,
            "patientIds": pageState.selectedPatientIds,
            "eventDateTime": moment(pageState.selectedEvent?.start).format("yyyy-MM-DD HH:mm"),
            "status": newStatus
        });
        fetch(appEndPoints.postEventInstanceStatusEndpoint.replace("{hostname}", appEndPoints.hostname),
            getRequestOptions('POST', body, 'json'))
            .then(resp => resp.text())
            .then(resp => {
                calendarRef.current.getApi().refetchEvents();
            })
            .catch(error => { console.log('error', error); });
        setMenuAnchor(null);
        setOpenDocumentationDialog(true);
    }

    const handleCloseDocumentationDialog = () => {
        setOpenDocumentationDialog(false);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };

    const handleRefreshCalendar = () => {
        setOpenEventDialog(false);
        calendarRef.current.getApi().refetchEvents();
    }

    const handleUserCalendarSwitchChange = (e) => {
        document.getElementById('hfSelectedUserId').value = e.target.value;
        const selectedUser = users.find(item => item.value === e.target.value);
        setPageState(old => ({ ...old, hiddenDaysInCalendar: selectedUser.hiddenDaysInCalendar, selectedUserId: e.target.value }));
        setRefreshCalendar(true);
    }

    const handleEventContent = (evt) => {
        try {
            const eventStartDateTime = moment(evt.event.start).format("yyyy-MM-DD HH:mm");
            if (evt.event.extendedProps.instances && evt.event.extendedProps.instances.length > 0) {
                evt.event.extendedProps.instances.forEach(instance => {
                    const eventInstanceDateTime = moment(instance.eventDateTime).format("yyyy-MM-DD HH:mm");
                    if (eventStartDateTime === eventInstanceDateTime) {
                        switch (instance.status) {
                            case 1:
                                evt.event.CssStatus = 'completed-event';
                                break;
                            case 2:
                                evt.event.CssStatus = 'canceled-event';
                                break;
                            case 3:
                                evt.event.CssStatus = 'canceled-late-event';
                                break;
                            case 4:
                                evt.event.CssStatus = 'reserved-event';
                                break;
                            default:
                                evt.event.CssStatus = '';
                                break;
                        }
                    }
                });
            }
            return (
                <div>
                    {getTitle(evt.event)} {evt.event.extendedProps.remainingAppointments ? '(' + evt.event.extendedProps.remainingAppointments + ')' : ''}
                </div>
            );
        }
        catch (e) {
            return (
                <div style={{ padding: '5px', borderRadius: '3px' }}>
                    NA
                </div>
            );
        }
    };

    const handleEventDidMount = (arg) => {
        // Check if the anchor element does not have the specified CSS class
        if (arg.event.CssStatus !== undefined && arg.event.CssStatus !== "" && !arg.el.classList.contains(arg.event.CssStatus)) {
            // Add the CSS class to the anchor element
            arg.el.classList.add(arg.event.CssStatus);
        }
    };

    const handleViewChange = (viewInfo) => {
        highlightAllDayEvents();
    };

    function getTitle(evt) {
        if (evt.title !== "" && evt.title !== null && evt.title !== undefined) {
            if (evt.extendedProps.patientIds && evt.extendedProps.patientIds.length > 1) {
                return (
                    <>
                        <Icon>people</Icon>&nbsp;{t("grouAppointment")}
                    </>
                );
            }
            else {
                return evt.title;
            }
        }
        else {
            return t("noTitle");
        }
    }

    function fetchUsers() {
        var serviceUrl = appEndPoints.getUsersEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                var elements = [];
                for (var i = 0; i < resp.length; i++) {
                    elements.push({
                        value: resp[i].id,
                        label: resp[i].firstName + ' ' + resp[i].lastName,
                        hiddenDaysInCalendar: resp[i].hiddenDaysInCalendar,
                    });
                }
                setUsers(elements);
            })
    }

    function highlightAllDayEvents() {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const allEvents = calendarApi.getEvents();
            const allDayEvents = allEvents
                .filter(event => event.allDay)
                .map(event => ({
                    start: moment(event.start).format('YYYY-MM-DD'),
                    end: moment(event.end).format('YYYY-MM-DD')
                }));
    
            const cells = document.querySelectorAll('td.fc-day');
            cells.forEach(cell => {
                const cellDate = moment(cell.getAttribute('data-date')).format('YYYY-MM-DD');
                
                const isWithinEventRange = allDayEvents.some(event => 
                    cellDate >= event.start && cellDate <= event.end
                );
                
                if (isWithinEventRange) {
                    cell.style.backgroundColor = '#808080';
                }
            });
        }
    }
    

    const fetchEvents = (fetchInfo, successCallback, failureCallback) => {
        if (!openEventDialog) {
            setPageState(old => ({ ...old, isLoading: true }));
            var userId = document.getElementById('hfSelectedUserId').value;
            var fromDate = moment(fetchInfo.start).format("yyyy-MM-DD");
            var serviceUrl = appEndPoints.getAllEventsEndpoint.replace("{hostname}", appEndPoints.hostname).replace("{userId}", !userId ? getCurrentUserId() : userId).replace("{userState}", getCurrentUser().state).replace("{fromDate}", fromDate);
            fetch(serviceUrl, getRequestOptions())
                .then(resp => resp.json())
                .then(async resp => {
                    setPageState(old => ({ ...old, isLoading: false, events: resp }),
                        successCallback(
                            resp.map(function (eventEl) {
                                return eventEl;
                            })
                        )
                    );
                    highlightAllDayEvents();
                })
                .catch(error => { setPageState(old => ({ ...old, isLoading: false })); console.log('error', error); failureCallback(error) })
        }
        else {
            successCallback(
                eventsRef.current.map(function (eventEl) {
                    return eventEl;
                })
            );
        }
    }

    // Effect to execute after refreshCalendar is set to true
    useEffect(() => {
        if (refreshCalendar) {
            calendarRef.current.getApi().refetchEvents();
            setRefreshCalendar(false); // Reset the state to prevent endless loop
        }
    }, [refreshCalendar]); // Dependencies array ensures the effect runs when refreshCalendar changes

    useEffect(() => {
        //highlightAllDayEvents();
        fetchUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PrivateLayout>
            <Card>
                <PPBox p={2}>
                    <Grid container>
                        <Grid item xs={8} md={8} lg={6}>
                            {
                                userHasAccess(ROLES.CALENDAR_VIEWER, getCurrentUser().roles) ?
                                    <div>
                                        Kalender wählen
                                        <Select value={pageState.selectedUserId} onChange={handleUserCalendarSwitchChange} className='selectList' sx={{ m: 1, minWidth: 200, maxHeight: 35 }}>
                                            {users.map(item => {
                                                return (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>);
                                            })}
                                        </Select>
                                    </div> : null
                            }
                            <input type="hidden" id="hfSelectedUserId" value={pageState.selectedUserId} />
                        </Grid>
                        <Grid item xs={4} md={4} lg={6} mt={1} textAlign="right">
                            <PPButton color="primary" onClick={handleAddEventDialog}>
                                <Icon>add_circle</Icon>&nbsp;
                                Hinzufügen
                            </PPButton>
                        </Grid>
                    </Grid>
                </PPBox>
                <PPBox pt={1} px={2}>
                    <ManageEvent eventId={pageState.selectedEventId} open={openEventDialog} handleCloseDialog={handleCloseEventDialog} handleRefreshCalendar={handleRefreshCalendar} formData={pageState.selectedEvent} />
                    {
                        openDocumentationDialog ?
                            <DocumentationDialog patientIds={pageState.selectedPatientIds} documentationDate={moment(pageState.selectedEvent?.start).format("yyyy-MM-DD HH:mm")} open={openDocumentationDialog} handleCloseDialog={handleCloseDocumentationDialog}></DocumentationDialog>
                            : null
                    }
                    <PPBox pb={2}>
                        <FullCalendar
                            ref={calendarRef}
                            locale={deLocale}
                            height='auto'
                            //timeZone='CET'
                            //themeSystem='bootstrap5'
                            headerToolbar={{
                                //start: "today prev next",
                                //end: "timeGridWeek dayGridDay",
                                left: 'prev,next today',
                                center: 'title',
                                right: 'dayGridMonth,timeGridWeek,dayGridDay',
                            }}
                            views={["dayGridMonth", "timeGridWeek", "dayGridDay"]}
                            plugins={[rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            //plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            handleWindowResize={true}
                            initialView="timeGridWeek"
                            slotMinTime='06:00:00'
                            slotMaxTime='20:00:00'
                            allDaySlot={true}
                            nowIndicator={true}
                            firstDay={1}
                            hiddenDays={pageState.hiddenDaysInCalendar} // 0 for Sunday, 6 for Saturday
                            editable={false}
                            viewDidMount={handleViewChange}
                            eventClick={handleEventClick}
                            initialEvents={fetchEvents}
                            dateClick={handleDateClick}
                            eventContent={handleEventContent}
                            eventDidMount={handleEventDidMount}
                        />
                        <Spinner open={pageState.isLoading} />
                        {
                            pageState.selectedPatientIds?.length > 0 ?
                                <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
                                    <MenuItem onClick={() => handleUpdateStatus(4)}><Icon color="primary">question_mark</Icon>&nbsp;Reserviert</MenuItem>
                                    <MenuItem onClick={() => handleUpdateStatus(0)}><Icon color="primary">schedule</Icon>&nbsp;Terminiert</MenuItem>
                                    <MenuItem onClick={() => handleUpdateStatus(1)}><Icon color="success">done</Icon>&nbsp;Wahrgenommen</MenuItem>
                                    <MenuItem onClick={() => handleUpdateStatus(2)}><Icon color="error">event_busy</Icon>&nbsp;Rechtzeitig abgesagt</MenuItem>
                                    <MenuItem onClick={() => handleUpdateStatus(3)}><Icon color="error">event_busy</Icon>&nbsp;Absage verspätet</MenuItem>
                                    <MenuItem onClick={() => { setOpenEventDialog(true); setMenuAnchor(null); }}><Icon color="primary">edit</Icon>&nbsp;Bearbeiten</MenuItem>
                                    <MenuItem onClick={() => { setOpenDocumentationDialog(true); setMenuAnchor(null); }}><Icon color="primary">attach_file</Icon>&nbsp;Dokumentation</MenuItem>
                                </Menu> :
                                <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
                                    <MenuItem onClick={() => { setOpenEventDialog(true); setMenuAnchor(null); }}><Icon color="primary">edit</Icon>&nbsp;Bearbeiten</MenuItem>
                                </Menu>
                        }
                    </PPBox>
                </PPBox>
                <ul className="legendCalendar">
                    <li><span className="reserved"></span> {t("reserved")}</li>
                    <li><span className="scheduled"></span> {t("scheduled")}</li>
                    <li><span className="completed"></span> {t("completed")}</li>
                    <li><span className="canceled"></span> {t("canceled")}</li>
                    <li><span className="canceledLate"></span> {t("canceledLate")}</li>
                    <li><span className="holiday"></span> {t("holiday")}</li>
                </ul>
            </Card>
        </PrivateLayout>
    );
});

export default Calendar;
