import jwt from "jwt-decode";
import PPBadge from "components/PPBadge";
import moment from 'moment';

export const weekdaysJson = [{ "shortName": "Mo", "longName": "Montag", "index": 1 }, { "shortName": "Di", "longName": "Dienstag", "index": 2 }, { "shortName": "Mi", "longName": "Mittwoch", "index": 3 }, { "shortName": "Do", "longName": "Donnerstag", "index": 4 }, { "shortName": "Fr", "longName": "Freitag", "index": 5 }, { "shortName": "Sa", "longName": "Samstag", "index": 6 }, { "shortName": "So", "longName": "Sonntag", "index": 0 }];

export const ROLES = {
  ADMINISTRATOR: "dc482a04-eebe-406b-aa6c-416fcf499173",
  LEAVE_REQUEST_MANAGER: "0b62ace9-2afe-4073-87c6-bbb1e1b82f80",
  CALENDAR_VIEWER: "385f257f-cea3-479a-90cc-8d4ea9164bd0",
};

export const onSuccessfulLogin = (token) => {
  const user = jwt(token);
  const currentUser = {
    id: user.sub,
    firstName: user.given_name,
    lastName: user.family_name,
    email: user.email,
    birthdate: user.birthdate,
    socialNumber: user.socialNumber,
    taxNumber: user.taxNumber,
    language: user.language,
    state: user.state,
    address: user.address ? JSON.parse(user.address) : null,
    roles: user.roles ? JSON.parse(user.roles) : null,
    hiddenDaysInCalendar: user.hiddenDaysInCalendar ? JSON.parse(user.hiddenDaysInCalendar) : '[0,6]',
    photoFileName: user.photoFileName,
    version: moment().format('DDMMYYYYHHmmss'),
  }
  window.localStorage.setItem('currentUser', JSON.stringify(currentUser));
  window.sessionStorage.setItem(user.sub, token);
  window.location.href = "/dashboard";
};

export const isAuthenticated = () => {
  const currentUser = getCurrentUser();
  if (currentUser !== null && currentUser !== undefined) {
    const token = sessionStorage.getItem(currentUser.id);
    if (token) {
      const decodedToken = jwt(token);;
      const currentTime = Date.now() / 1000;

      return decodedToken.exp > currentTime;
    }
  }
  return false;
};

export const getAccessToken = () => {
  const currentUser = getCurrentUser();
  if (currentUser !== null && currentUser !== undefined) {
    return sessionStorage.getItem(currentUser.id);
  }
  return '';
};

export const getCurrentUser = () => {
  return JSON.parse(window.localStorage.getItem('currentUser'));
};

export const getCurrentUserId = () => {
  return JSON.parse(window.localStorage.getItem('currentUser')).id;
};

export const getUserPracticeId = () => {
  return JSON.parse(window.localStorage.getItem('currentUser')).practiceId;
};

export const getRequestOptions = (requestMethod, bodyContent, format) => {
  var requestOptions = {
    method: requestMethod ? requestMethod : 'GET',
    headers: {
      'Authorization': 'Bearer ' + getAccessToken(),
    }
  };
  if (format === '' || format === 'json')
    requestOptions.headers['Content-Type'] = 'application/json';
  if (requestMethod !== 'GET' && bodyContent !== null && bodyContent !== undefined) {
    requestOptions.body = bodyContent;
  }
  return requestOptions;
};

export const userHasAccess = (roleToCheck, userRoles) => {
  // Check if userRoles is null or undefined, return false if so
  if (userRoles == null) {
    return false;
  }
  // Check if the user is an administrator or has the specified role
  return (
    userRoles.some(x => x.id === ROLES.ADMINISTRATOR) ||
    userRoles.some(x => x.id === roleToCheck)
  );
};

export const userHasRole = (roleToCheck, userRoles) => {
  // Check if userRoles is null or undefined, return false if so
  if (userRoles == null) {
    return false;
  }
  // Check if the user is an administrator or has the specified role
  return (userRoles.some(x => x.id === roleToCheck));
};

export const truncateHTML = (content, maxLength) => {
  if (content.length > maxLength) {
    // Create a temporary element to convert HTML to plain text
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;

    // Get the plain text from the temporary element
    const plainText = tempElement.textContent || tempElement.innerText;

    // Truncate the plain text
    const truncatedText = plainText.length > maxLength
      ? `${plainText.slice(0, maxLength)}...`
      : plainText;

    // Create a new temporary element to convert plain text back to HTML
    const truncatedElement = document.createElement('div');
    truncatedElement.textContent = truncatedText;
    // Return the truncated content as HTML
    return truncatedElement.innerHTML;
  }
  return content;
}

export const currentUserHasAccessToPage = (pathname) => {
  if (pathname === undefined || pathname === null) {
    return false;
  }
  else if (pathname === '/users' && !userHasRole(ROLES.ADMINISTRATOR, getCurrentUser().roles)) {
    return false;
  }
  else if (pathname === '/settings' && !userHasRole(ROLES.ADMINISTRATOR, getCurrentUser().roles)) {
    return false;
  }
  return true;
}

export const getLeaveRequestStatusAsBadge = (status) => {
  //const { t } = useTranslation();
  switch (status) {
    case 1:
      return <PPBadge badgeContent="Genehmigt" color="success" variant="gradient" size="md" />;
    case 2:
      return <PPBadge badgeContent="Abgelehnt" color="error" variant="gradient" size="md" />;
    case 3:
      return <PPBadge badgeContent="Abgesagt" color="error" variant="gradient" size="md" />;
    default:
      return <PPBadge badgeContent="Fällig" color="warning" variant="gradient" size="md" />;
  }
};

export const getLeaveRequestTypeAsText = (status) => {
  switch (status) {
    case 1:
      return "Jahresurlaub";
    case 2:
      return "Krankheit";
    case 3:
      return "Elternzeit";
    case 4:
      return "Unbezahlter Urlaub";
    case 5:
      return "Trauerfall";
    case 6:
      return "Krankheit Kind";
    case 7:
      return "Bezahlte Abwesenheit";
    default:
      return "Jahresurlaub";
  }
};

export const getLeaveRequestStatusFromText = (status) => {
  switch (status) {
    case 'Approve':
      return 1;
    case 'Reject':
      return 2;
    case 'Cancel':
      return 3;
    default:
      return 0;
  }
};

export const getUserStatusAsBadge = (status) => {
  switch (status) {
    case 1:
      return <PPBadge badgeContent="Aktiv" color="success" variant="gradient" size="md" />;
    case 2:
      return <PPBadge badgeContent="Inaktiv" color="error" variant="gradient" size="md" />;
    case 3:
      return <PPBadge badgeContent="Eingeladen" color="warning" variant="gradient" size="md" />;
    case 4:
      return <PPBadge badgeContent="E-Mail bestätigt" color="warning" variant="gradient" size="md" />;
    case 5:
      return <PPBadge badgeContent="Gelöscht" color="error" variant="gradient" size="md" />;
    default:
      return <PPBadge badgeContent="Aktiv" color="success" variant="gradient" size="md" />;
  }
};

export const setPageTitle = (title) => {
  document.title = title + ' - PraxiPro';
};