import PropTypes from "prop-types";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import routes from "routes";

function Breadcrumbs({ name }) {
  const currentRoute = routes.find(x => x.key === name);
  const title = currentRoute.name;
  return (
    <PPBox mr={{ xs: 0, xl: 8 }}>
      <PPTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h5"
        color="dark"
        noWrap
      >
        {title.replace("-", " ")}
      </PPTypography>
    </PPBox>
  );
}

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Breadcrumbs;
