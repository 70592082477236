import React, { useState } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";

function Note({ content }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const icon = isExpanded ? "expand_less" : "expand_more";
  const color = "success";

  const maxCharacters = 90;
  const truncatedContent =
    content.length > maxCharacters
      ? isExpanded
        ? content
        : truncateHTML(content, maxCharacters)
      : content;

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  function truncateHTML(content, maxLength) {
    // Create a temporary element to convert HTML to plain text
    const tempElement = document.createElement('div');
    tempElement.innerHTML = content;

    // Get the plain text from the temporary element
    const plainText = tempElement.textContent || tempElement.innerText;

    // Truncate the plain text
    const truncatedText = plainText.length > maxLength
      ? `${plainText.slice(0, maxLength)}...`
      : plainText;

    // Create a new temporary element to convert plain text back to HTML
    const truncatedElement = document.createElement('div');
    truncatedElement.textContent = truncatedText;

    // Return the truncated content as HTML
    return truncatedElement.innerHTML;
  }

  return (
    <PPBox component="li" py={1} pr={2} mb={1}>
      <PPBox display="flex" justifyContent="space-between" alignItems="center">
        <PPBox display="flex" alignItems="center">
          <PPBox mr={2}>
            <PPButton variant="outlined" color={color} iconOnly circular onClick={handleButtonClick}>
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </PPButton>
          </PPBox>
          <PPBox display="flex" flexDirection="column">
            <PPTypography variant="caption" color="text" fontWeight="regular"
              style={{ maxHeight: isExpanded ? "none" : "30px", overflow: "hidden" }}>
              <div dangerouslySetInnerHTML={{ __html: truncatedContent }} />
            </PPTypography>
          </PPBox>
        </PPBox>
      </PPBox>
    </PPBox>
  );
}

// Typechecking props of the Transaction
Note.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Note;
