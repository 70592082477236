import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import Icon from "@mui/material/Icon";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "controls/Tabs/TabPanel";
import BasicInfo from "./components/BasicInfo";
import Preferences from "./components/Preferences";
import { setPageTitle } from "controls/Helpers";
import { useTranslation } from 'react-i18next';

function MyProfile() {
    const { t } = useTranslation();
    setPageTitle(t('myProfile'));
    const [pageState, setPageState] = useState({
        isLoading: false,
        tabIndex: 0,
    });

    const handleTabsChange = (e, newValue) => {
        setPageState(x => ({ ...x, tabIndex: newValue }));
    };

    return (
        <PrivateLayout>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} lg={2}>
                    <Card>
                        <PPBox pt={0} pb={3}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <PPBox pt={3}>
                                        <Tabs className='verticalTabs' orientation="vertical" value={pageState.tabIndex} onChange={handleTabsChange}>
                                            <Tab label="Mein Profil" icon={<Icon fontSize="medium" sx={{ mt: -0.25 }}>settings</Icon>} />
                                            <Tab label="Einstellungen" icon={<Icon fontSize="medium" sx={{ mt: -0.25 }}>event</Icon>} />
                                        </Tabs>
                                    </PPBox>
                                </Grid>
                            </Grid>
                        </PPBox>
                    </Card>
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                    <Card>
                        <TabPanel value={pageState.tabIndex} index={0}>
                            <PPBox pt={3} pb={3}>
                                <BasicInfo />
                            </PPBox>
                        </TabPanel>
                        <TabPanel value={pageState.tabIndex} index={1}>
                            <PPBox pt={3} pb={3}>
                                <Preferences />
                            </PPBox>
                        </TabPanel>
                    </Card>
                </Grid>
            </Grid>
        </PrivateLayout>
    );
}

export default MyProfile;

