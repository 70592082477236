/** 
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
//Search icons using this link:
//https://fonts.google.com/icons?icon.set=Material+Icons&icon.query=football&icon.style=Outlined
//Reference: https://marmelab.com/react-admin-crm/#/contacts/393/show

import Dashboard from "pages/dashboard";
import Profile from "pages/profile";
import Calendar from "pages/calendar";
import Patients from "pages/patients";
import Leaves from "pages/leaves";
import Users from "pages/users";
import Settings from "pages/settings";
import Notes from "pages/notes";
import Inquiries from "pages/inquiries";
import SignIn from "pages/account/sign-in";
import SignUp from "pages/account/sign-up";
import ChangePassword from "pages/account/change-password";
import ResetPassword from "pages/account/reset-password";
import ForgotPassword from "pages/account/forgot-password";
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "hidden",
    name: "SignIn",
    key: "sign-in",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/sign-in",
    access: "public",
    component: <SignIn />,
  },
  {
    type: "hidden",
    name: "SignUp",
    key: "sign-up",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/sign-up",
    access: "public",
    component: <SignUp />,
  },
  {
    type: "hidden",
    name: "Kennwort ändern",
    key: "change-password",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/change-password",
    access: "private",
    component: <ChangePassword />,
  },
  {
    type: "hidden",
    name: "ResetPassword",
    key: "reset-password",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/reset-password",
    access: "public",
    component: <ResetPassword />,
  },
  {
    type: "hidden",
    name: "ForgotPassword",
    key: "forgot-password",
    icon: <Icon fontSize="small">key</Icon>,
    route: "/forgot-password",
    access: "public",
    component: <ForgotPassword />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    access: "private",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Kalender",
    key: "calendar",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/calendar",
    access: "private",
    component: <Calendar />,
  },
  {
    type: "collapse",
    name: "Patienten",
    key: "patients",
    icon: <Icon fontSize="small">contacts</Icon>,
    route: "/patients",
    access: "private",
    component: <Patients />,
  },
  {
    type: "hidden",
    name: "Inquiries",
    key: "inquiries",
    icon: <Icon fontSize="small">contacts</Icon>,
    route: "/inquiries",
    access: "private",
    component: <Inquiries />,
  },
  {
    type: "collapse",
    name: "Notiztafel",
    key: "notes",
    icon: <Icon fontSize="small">sticky_note_2</Icon>,
    route: "/notes",
    access: "private",
    component: <Notes />,
  },
  {
    type: "collapse",
    name: "Abwesenheit",
    key: "leaves",
    icon: <Icon fontSize="small">luggage</Icon>,
    route: "/leaves",
    access: "private",
    component: <Leaves />,
  },
  {
    type: "collapse",
    name: "Benutzer",
    key: "users",
    icon: <Icon fontSize="small">people</Icon>,
    route: "/users",
    access: "private",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Einstellungen",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    access: "private",
    component: <Settings />,
  },
  {
    type: "hidden",
    name: "Mein Profil",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    access: "private",
    component: <Profile />,
  },
  // {
  //   type: "collapse",
  //   href: "/",
  //   name: "account",
  //   title: "account",
  //   access: "public",
  //   dropdown: true,
  //   icon: <Icon>contacts</Icon>,
  //   collapse: [
  //     {
  //       name: "sign in",
  //       access: "public",
  //       dropdown: true,
  //       href: "/",
  //       collapse: [
  //         {
  //           name: "basic",
  //           route: "/account/sign-in",
  //           component: <SignIn />,
  //         }
  //       ],
  //     },
  //     {
  //       name: "sign up",
  //       access: "public",
  //       dropdown: true,
  //       href: "/",
  //       collapse: [
  //         {
  //           name: "cover",
  //           route: "/account/sign-up",
  //           component: <SignUp />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   access: "private",
  //   component: <Tables />,
  // },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   access: "private",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   access: "private",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   access: "private",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Anmelden",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/account/sign-in",
  //   access: "public",
  //   component: <SignIn />,
  // },
  // {
  //   type: "hidden",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/account/sign-up",
  //   access: "public",
  //   component: <SignUp />,
  // },
];

export default routes;
