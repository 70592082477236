import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import { getCurrentUserId, getLeaveRequestStatusAsBadge, getLeaveRequestTypeAsText } from "controls/Helpers";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function LeaveRequestCard(args) {
    const { t } = useTranslation();
    const [menu, setMenu] = useState(false);
    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(false);

    const handleApproveClick = () => {
        args.handleApprovalClick(args.id, 'Approve');
        closeMenu();
    }

    const handleEditClick = () => {
        args.handleEditClick(args.id);
        closeMenu();
    }

    const handleRejectClick = () => {
        args.handleApprovalClick(args.id, 'Reject');
        closeMenu();
    }

    const handleViewClick = () => {
        args.handleApprovalClick(args.id, 'View');
        closeMenu();
    }

    const handleDeleteClick = () => {
        //TODO: canceling the leave request should be allowed only if the start date is in the future
        args.handleDeleteClick(args.id);
        closeMenu();
    }

    return (
        <PPBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            borderRadius="lg" p={2} coloredShadow="light">
            <PPBox width="100%" display="flex" flexDirection="column">
                <Grid container mb={2}>
                    <Grid item xs={10} md={10} lg={10}>
                        <PPTypography component="a" href="#" onClick={handleViewClick} variant="button" fontWeight="bold" textTransform="capitalize">
                            {getLeaveRequestTypeAsText(args.type)} {getLeaveRequestStatusAsBadge(args.status)}
                        </PPTypography>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} textAlign={"right"}>
                        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
                            more_vert
                        </Icon>
                        <Menu anchorEl={menu}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(menu)} onClose={closeMenu}>
                            {
                                (args.requestedByUserId === getCurrentUserId()) ?
                                    <MenuItem color="error" onClick={handleEditClick}>
                                        <Icon>edit</Icon>&nbsp;{t("edit")}
                                    </MenuItem> : null
                            }
                            <MenuItem onClick={handleViewClick}>
                                <Icon>assignment</Icon>&nbsp;{t("view")}
                            </MenuItem>
                            {
                                (args.requestedByUserId === getCurrentUserId() && moment(args.startDate) >= moment(new Date())) || (args.isLeaveRequestManager) ?
                                    <MenuItem onClick={handleDeleteClick}>
                                        <Icon>delete</Icon>&nbsp;{t("delete")}
                                    </MenuItem> : null
                            }
                        </Menu>
                    </Grid>
                </Grid>
                <PPTypography mb={1.5} variant="caption" fontWeight="medium">
                    <Icon>schedule</Icon> Startdatum: {moment(args.startDate).format("DD.MM.YYYY")}
                </PPTypography>
                <PPTypography mb={1.5} variant="caption" fontWeight="medium">
                    <Icon>schedule</Icon> Enddatum: {moment(args.endDate).format("DD.MM.YYYY")}
                </PPTypography>
                <PPTypography mb={1.5} variant="caption" fontWeight="medium">
                    <Icon>numbers</Icon> Dauer (in Tagen): {args.duration}
                </PPTypography>
                <PPTypography mb={1.5} variant="caption" fontWeight="medium">
                    <Icon>person</Icon> Angefordert von: {args.requestedBy}
                </PPTypography>
                <div style={{ textAlign: "right", height: "40px" }}>
                    {
                        args.status === 0 && args.requestedByUserId !== getCurrentUserId() ?
                            <PPButton onClick={handleApproveClick} style={{ marginRight: "5px" }} variant="outlined" color="success" size="small">
                                <Icon>thumb_up_off_alt</Icon>&nbsp;{t('approve')}
                            </PPButton> : null
                    }
                    {
                        args.status === 0 && args.requestedByUserId !== getCurrentUserId() ?
                            <PPButton onClick={handleRejectClick} style={{ marginRight: "5px" }} variant="outlined" color="error" size="small">
                                <Icon>thumb_down_off_alt</Icon>&nbsp;{t('reject')}
                            </PPButton> : null
                    }
                </div>
            </PPBox>
        </PPBox>
    );
}

export default LeaveRequestCard;