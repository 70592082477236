import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import PPBox from "components/PPBox";
import { usePraxiProUIController, setLayout } from "context";

function PublicLayout({ background, children }) {
  const [, dispatch] = usePraxiProUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
    window.localStorage.clear();
    window.sessionStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <PPBox
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}>
      {children}
    </PPBox>
  );
}

// Setting default values for the props for PublicLayout
PublicLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PublicLayout
PublicLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PublicLayout;
