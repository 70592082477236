import PPBox from "components/PPBox";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import {
  Card,
} from '@mui/material';
import EmployeeOverview from "./components/EmployeeOverview";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {setPageTitle} from "controls/Helpers";
import { useTranslation } from 'react-i18next';

function Dashboard() {
  const { t } = useTranslation();
  setPageTitle(t('dashboard'));
  return (
    <PrivateLayout>
      <Card>
        <PPBox p={2}>
          {/* Notes App https://templates.iqonic.design/note-plus/html/backend/index.html, https://mui.com/store/previews/minimal-dashboard/ */}
          <EmployeeOverview />
        </PPBox>
      </Card>
    </PrivateLayout>
  );
}

export default Dashboard;
