import React, { useState, useEffect } from 'react';
import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequestOptions, getCurrentUserId } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import Switch from "@mui/material/Switch";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Spinner from "controls/Spinner";
import PPTypography from "components/PPTypography";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';

const NoteEditor = ({ open, noteId, handleRefreshListing, handleCloseDialog }) => {
  const { t } = useTranslation();
  const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
  const [pageState, setPageState] = useState({
    id: noteId,
    content: '',
    shared: false,
    createdBy: '',
    imageTempUrl: '',
    imageUrl: '',
    photo: null,
    charCount: 0,
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const maxPlainTextChars = 850;

  const handleContentChange = (html) => {
    // Strip HTML tags and replace &nbsp; with spaces
    const plainText = html.replace(/<(.|\n)*?>/g, '').replace(/&nbsp;/g, '');

    // If the plain text length is within the limit, update the state normally
    if (plainText.length <= maxPlainTextChars) {
      setPageState(x => ({ ...x, content: html || '', charCount: plainText.length }));
    } else {
      // If plain text exceeds the limit, trim it and create a new HTML content accordingly
      //const trimPlainText = plainText.substring(0, maxPlainTextChars);

      // Calculate the length difference
      const diffLength = plainText.length - maxPlainTextChars;

      // Function to trim HTML content to match the trimmed plain text length
      const trimHTML = (html, diffLength) => {
        //let trimmedHTML = html;
        let charCount = 0;
        let lastValidIndex = 0;

        for (let i = 0; i < html.length; i++) {
          if (html[i] === '<') {
            // Skip HTML tags
            while (html[i] !== '>' && i < html.length) {
              i++;
            }
          } else if (html[i] === '&' && html.slice(i, i + 6) === '&nbsp;') {
            // Replace &nbsp; with a space and count as one character
            i += 5; // Move index to the end of &nbsp;
            charCount++;
          } else {
            charCount++;
          }

          if (charCount <= maxPlainTextChars) {
            lastValidIndex = i;
          } else {
            break;
          }
        }

        return html.substring(0, lastValidIndex + 1);
      };

      const trimmedHTML = trimHTML(html, diffLength);

      setPageState(x => ({ ...x, content: trimmedHTML, charCount: maxPlainTextChars }));
    }
  };

  const handleSharedSwitch = () => {
    setPageState(x => ({ ...x, shared: !pageState.shared }));
  };

  const handleCloseEditor = (e) => {
    handleCloseDialog();
    setPageState(x => ({
      ...x, id: '', content: '', shared: false,
      createdBy: '',
      imageTempUrl: '', imageUrl: '', photo: null, joiningDate: '',
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (pageState.content.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      return
    }
    setOpenSpinnerPanel(true);
    var body = JSON.stringify({
      "id": pageState.id ? pageState.id : null,
      "content": pageState.content ? pageState.content : '',
      "shared": pageState.shared ? pageState.shared : false,
    });

    var fetchURL = appEndPoints.postNoteEndpoint.replace("{hostname}", appEndPoints.hostname);
    var requestOptions = getRequestOptions('POST', body, 'json');
    if (pageState.id !== null && pageState.id !== '') {
      fetchURL = appEndPoints.putNoteEndpoint.replace("{hostname}", appEndPoints.hostname);
      requestOptions = getRequestOptions('PUT', body, 'json');
    }
    fetch(fetchURL, requestOptions)
      .then(resp => resp.text())
      .then(resp => {
        handleRefreshListing();
        handleCloseEditor();
        setOpenSpinnerPanel(false);
      })
      .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
  };

  const handleNoteDeleteClick = (e) => {
    e.preventDefault();
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    var body = JSON.stringify({
      "id": pageState.id,
    });
    var serviceUrl = appEndPoints.deleteNoteEndpoint.replace("{hostname}", appEndPoints.hostname);

    fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
      .then(resp => resp.json())
      .then(resp => {
        handleRefreshListing();
        handleCloseEditor();
      })
      .catch(error => { console.log('error', error); })
  };

  function fetchNoteDetails() {
    setPageState(x => ({ ...x, id: '', imageUrl: '' })); // this is needed to avoid errors when switching between users
    if (noteId !== null && noteId !== '' && open === true) {
      setOpenSpinnerPanel(true);
      var fetchURL = appEndPoints.getNotesEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + noteId;
      fetch(fetchURL, getRequestOptions())
        .then(resp => resp.json())
        .then(resp => {
          const plainText = resp.content.replace(/<(.|\n)*?>/g, '').replace(/&nbsp;/g, '');
          setPageState(x => ({
            ...x, id: resp.id,
            content: resp.content,
            shared: resp.shared,
            createdBy: resp.createdBy,
            charCount: plainText.length,
          }));
          setOpenSpinnerPanel(false);
        })
        .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    }
    else {
      setPageState(x => ({
        ...x, id: '', content: '', shared: false, createdBy: '', charCount: 0
      }));
    }
  }

  useEffect(() => {
    if (pageState.photo) {
      setPageState(x => ({ ...x, imageTempUrl: URL.createObjectURL(pageState.photo) }));
    }
  }, [pageState.photo]);

  useEffect(() => {
    fetchNoteDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, noteId]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
      if (reason !== "backdropClick") {
        handleCloseEditor();
      }
    }} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        {
          noteId !== null && noteId !== '' ?
            <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>sticky_note_2</Icon>&nbsp;Einzelheiten</div>
            : <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>sticky_note_2</Icon>&nbsp;Neuer Notiz</div>
        }
      </DialogTitle>
      <DialogContent dividers style={{ minHeight: "72vh" }}>
        <form id="formManageNote" method="post" onSubmit={handleFormSubmit}>
          <PPBox mb={2}>
            <ReactQuill theme="snow" value={pageState.content} onChange={handleContentChange} required />
          </PPBox>
          <PPBox display="flex" justifyContent="flex-end">
            <PPTypography variant="caption" color="text">
              {`${pageState.charCount}/${maxPlainTextChars} Zeichen`}
            </PPTypography>
          </PPBox>
          <PPBox>
            <PPBox mt={1} lineHeight={1}>
              <span>{t("shared")}</span>
              <Switch checked={pageState.shared} onChange={handleSharedSwitch} />
            </PPBox>
          </PPBox>
        </form>
      </DialogContent>
      <DialogActions>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
              <Icon>cancel</Icon>&nbsp;Schließen
            </PPButton>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            {
              noteId !== null && noteId !== '' && pageState.createdBy !== getCurrentUserId() ? null :
                <PPButton variant="gradient" color="error" onClick={handleNoteDeleteClick} fullWidth>
                  <Icon>delete</Icon>&nbsp;löschen
                </PPButton>
            }
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <PPButton variant="gradient" color="primary" type="submit" form="formManageNote" fullWidth>
              <Icon>save</Icon>&nbsp;Speichern
            </PPButton>
          </Grid>
        </Grid>
      </DialogActions>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        message={`Sind Sie sicher, dass Sie diese Notiz löschen möchten?`}
      />
      <Spinner open={openSpinnerPanel} />
    </Dialog >
  );
}

export default NoteEditor;