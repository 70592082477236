import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import LeaveRequestCard from "./LeaveRequestCard";
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import PPButton from "components/PPButton";
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import NoRecords from "controls/NoRecords";

const LeaveRequestsListing = forwardRef((args, ref) => {
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        totalRecords: 0,
        currentPage: 1,
        pageSize: 10,
        searchQuery: args.searchQuery,
        refreshListing: args.refreshListing,
        handleSearch: args.handleSearch,
    });

    useImperativeHandle(ref, () => ({
        refreshListing() {
            setPageState(old => ({ ...old, data: [], currentPage: 1, searchQuery: '' }), fetchGridData());
        },
    }));

    const handleLoadMore = (e) => {
        e.preventDefault();
        setPageState(old => ({ ...old, currentPage: pageState.currentPage + 1 }))
    }

    function fetchGridData() {
        setPageState(old => ({ ...old, isLoading: true }));
        const selectedUserId = document.getElementById('hfSelectedUserId').value;
        const selectedLeavesStatusViewId = document.getElementById('hfSelectedLeavesStatusViewId').value;
        var fetchURL = appEndPoints.getLeaveRequestsListingEndpoint.replace("{hostname}", appEndPoints.hostname).replace("{userId}", selectedUserId).replace("{status}", selectedLeavesStatusViewId).replace("{pageNumber}", pageState.currentPage).replace("{pageSize}", pageState.pageSize);
        fetch(fetchURL, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                let currentData = resp.data;
                if (pageState.currentPage > 1) {
                    currentData = pageState.data;
                    resp.data.map((e, index) => (
                        currentData.push(e)
                    ))
                }
                //TODO: set totalRecords
                setPageState(old => ({ ...old, isLoading: false, data: currentData, totalRecords: resp.totalRecords }));
                // if (settings.handleCallback != null)
                //     settings.handleCallback(json.totalCount);
            })
            .catch(error => { console.log('error', error); setPageState(old => ({ ...old, isLoading: false })); })
    }

    useEffect(() => {
        fetchGridData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.currentPage, pageState.pageSize])

    return (
        <Grid container spacing={2}>
            {
                pageState.data.map((x, j) => (
                    <Grid item xs={12} md={6} xl={4} key={j}>
                        <LeaveRequestCard
                            id={x.id}
                            type={x.type}
                            startDate={x.startDate}
                            endDate={x.endDate}
                            duration={x.duration}
                            status={x.status}
                            requestedBy={x.requestedByUser.firstName + ' ' + x.requestedByUser.lastName}
                            requestedByUserId={x.requestedBy}
                            handleApprovalClick={args.handleApprovalClick}
                            handleEditClick={args.handleEditClick}
                            handleDeleteClick={args.handleDeleteClick}
                            isLeaveRequestManager={args.isLeaveRequestManager}
                        />
                    </Grid>
                ))
            }
            {
                (pageState.data === null || pageState.data.length === 0) && !pageState.isLoading ?
                    <NoRecords />
                    : null
            }
            {
                ((pageState.currentPage) * pageState.pageSize >= pageState.totalRecords) ?
                    null :
                    <PPBox style={{ width: "100%", paddingTop: "40px", paddingLeft: "15px", textAlign: "center" }}>
                        <PPButton variant="gradient" color="primary" type="button" onClick={handleLoadMore} disabled={pageState.isLoading}>
                            {pageState.isLoading ? <CircularProgress size={15} style={{ marginRight: "10px" }} /> : null}
                            Mehr laden
                        </PPButton>
                    </PPBox>
            }
            {
                pageState.currentPage === 1 && pageState.isLoading ?
                    <div style={{ width: "100%", textAlign: "center", marginTop: "20px", paddingTop: "30px" }}>
                        <CircularProgress style={{ marginRight: "10px" }} />
                    </div>
                    : null
            }
        </Grid>
    )
});

export default LeaveRequestsListing