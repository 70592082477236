import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import moment from "moment";
import PPSnackbar from "components/PPSnackbar";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import Spinner from "controls/Spinner";
import { useTranslation } from 'react-i18next';

function PatientEditor({ open, patientId, handleRefreshListing, handlePatientDeleteClick, handleCloseDialog }) {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        id: patientId,
        firstName: '',
        lastName: '',
        birthdate: '',
        email: '',
        primaryPhone: '',
        secondaryPhone: '',
        city: null,
        street: null,
        postalCode: null,
        imageTempUrl: '',
        imageUrl: '',
        photo: null,
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [errorSB, setErrorSB] = useState(false);
    const closeErrorSB = () => setErrorSB(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleFirstNameChange = (e) => {
        setPageState(x => ({ ...x, firstName: e.target.value ? e.target.value : '' }));
    };
    const handleLastNameChange = (e) => {
        setPageState(x => ({ ...x, lastName: e.target.value ? e.target.value : '' }));
    };
    const handleEmailChange = (e) => {
        setPageState(x => ({ ...x, email: e.target.value ? e.target.value : '' }));
    };
    const handlePrimaryPhoneChange = (e) => {
        setPageState(x => ({ ...x, primaryPhone: e.target.value ? e.target.value : '' }));
    };
    const handleSecondaryPhoneChange = (e) => {
        setPageState(x => ({ ...x, secondaryPhone: e.target.value ? e.target.value : '' }));
    };
    const handleCityChange = (e) => {
        setPageState(x => ({ ...x, city: e.target.value ? e.target.value : '' }));
    };
    const handleStreetChange = (e) => {
        setPageState(x => ({ ...x, street: e.target.value ? e.target.value : '' }));
    };
    const handlePostalCodeChange = (e) => {
        setPageState(x => ({ ...x, postalCode: e.target.value ? e.target.value : '' }));
    };
    const handleCloseEditor = (e) => {
        handleCloseDialog();
        setPageState(x => ({
            ...x, id: '', firstName: '', lastName: '',
            birthdate: null, email: '',
            primaryPhone: '', secondaryPhone: '',
            city: '', street: '', postalCode: '',
            imageTempUrl: '', imageUrl: '', photo: null
        }));
    };

    const handleFileChange = (event) => {
        // Validate file extension
        const allowedExtensions = ['jpg', 'jpeg', 'png'];
        const file = event.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (file.size > 2 * 1024 * 1024) {
                setErrorMessage("The photo size exceeds 2MB limit.");
                setErrorSB(true);
            }
            else if (!allowedExtensions.includes(fileExtension)) {
                setErrorMessage("Please select a valid image file");
                setErrorSB(true);
            }
            else {
                setErrorSB(false);
                setPageState(x => ({ ...x, photo: file }));
            }
        }
    };

    const handlePatientFormSubmit = (e, ignoreConflict) => {
        if (e !== null) e.preventDefault();
        setOpenSpinnerPanel(true);
        var submitData = new FormData();
        submitData.append("firstName", pageState.firstName);
        submitData.append("lastName", pageState.lastName);
        submitData.append("birthdate", pageState.birthdate ? pageState.birthdate : '');
        submitData.append("email", pageState.email ? pageState.email : '');
        submitData.append("primaryPhone", pageState.primaryPhone ? pageState.primaryPhone : '');
        submitData.append("secondaryPhone", pageState.secondaryPhone ? pageState.secondaryPhone : '');
        submitData.append("city", pageState.city ? pageState.city : '');
        submitData.append("street", pageState.street ? pageState.street : '');
        submitData.append("postalCode", pageState.postalCode ? pageState.postalCode : '');
        submitData.append("photoFile", pageState.photo ? pageState.photo : '');
        submitData.append("ignoreConflict", ignoreConflict ? ignoreConflict : false);

        var fetchURL = appEndPoints.postPatientEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('POST', submitData);
        if (pageState.id !== null && pageState.id !== '') {
            fetchURL = appEndPoints.putPatientEndpoint.replace("{hostname}", appEndPoints.hostname);
            submitData.append("id", pageState.id ? pageState.id : null);
            requestOptions = getRequestOptions('PUT', submitData);
        }
        var status = 200;
        fetch(fetchURL, requestOptions)
            .then(resp => {
                // Check the response status here
                status = resp.status;
                resp.text();
            })
            .then(resp => {
                if (status === 409) {
                    setConfirmDialogOpen(true);
                }
                else if (status === 200) {
                    setConfirmDialogOpen(false);
                    handleRefreshListing();
                    handleCloseEditor();
                }
                setOpenSpinnerPanel(false);
            })
            .catch(error => { setOpenSpinnerPanel(false); setConfirmDialogOpen(false); console.log('error', error); })
    };

    const handleDeleteClick = (e) => {
        e.preventDefault();
        handlePatientDeleteClick(pageState.id);
    };

    const handleConfirmAddingPatientWithSameName = () => {
        handlePatientFormSubmit(null, true)
    };

    function fetchPatientDetails() {
        if (open && patientId !== null && patientId !== '') {
            setOpenSpinnerPanel(true);
            var fetchURL = appEndPoints.getPatientsEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + patientId;
            fetch(fetchURL, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, id: resp.id,
                        firstName: resp.firstName,
                        lastName: resp.lastName,
                        birthdate: resp.birthdate ? resp.birthdate : null,
                        email: resp.email,
                        primaryPhone: resp.primaryPhone ? resp.primaryPhone : '',
                        secondaryPhone: resp.secondaryPhone ? resp.secondaryPhone : '',
                        city: resp.address && resp.address.city ? resp.address.city : null,
                        street: resp.address && resp.address.street ? resp.address.street : null,
                        postalCode: resp.address && resp.address.postalCode ? resp.address.postalCode : null,
                    }));
                    if (resp.photoFileName) {
                        var fetchURL = appEndPoints.getFileBinary.replace("{hostname}", appEndPoints.hostname).replace("{id}", patientId).replace("{fileName}", resp.photoFileName);
                        fetch(fetchURL, getRequestOptions())
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error('Error occured');
                                }
                                return response.blob();
                            })
                            .then(blob => {
                                setPageState(x => ({ ...x, imageUrl: URL.createObjectURL(blob) }));
                            })
                            .catch(error => {
                                console.error('Fetch error:', error);
                            });
                    }
                    setOpenSpinnerPanel(false);
                })
                .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
        }
        else {
            setPageState(x => ({
                ...x, id: '', firstName: '', lastName: '',
                birthdate: null, email: '',
                primaryPhone: '', secondaryPhone: '',
                city: '', street: '', postalCode: '',
            }));
        }
    }

    useEffect(() => {
        if (pageState.photo) {
            setPageState(x => ({ ...x, imageTempUrl: URL.createObjectURL(pageState.photo) }));
        }
    }, [pageState.photo]);

    useEffect(() => {
        fetchPatientDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, patientId]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseEditor();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                {
                    patientId !== null && patientId !== '' ?
                        <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>badge</Icon>&nbsp;Einzelheiten</div>
                        : <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>badge</Icon>&nbsp;Neuer Patient</div>
                }
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <form id="formManagePatient" method="post" onSubmit={handlePatientFormSubmit}>
                    <PPBox mb={2}>
                        <input
                            accept=".jpg,.png,.jpeg"
                            type="file"
                            id="select-image"
                            name="photo"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <div className="profile-photo-container">
                            {(pageState.imageUrl !== null && pageState.imageUrl !== '') || (pageState.imageTempUrl !== null && pageState.imageTempUrl !== '') ? (
                                <img src={pageState.imageTempUrl ? pageState.imageTempUrl : pageState.imageUrl} alt={pageState.firstName} className="profile-photo" />
                            ) : <div className="upload-photo-placeholder">Patientenfoto hochladen</div>}
                        </div>
                        <label htmlFor="select-image">
                            <PPButton variant="gradient" color="primary" component="span">
                                <Icon>save</Icon>&nbsp;Foto hochladen
                            </PPButton>
                        </label>
                        <PPSnackbar
                            color="error"
                            icon="warning"
                            title="Error"
                            content={errorMessage}
                            dateTime=""
                            open={errorSB}
                            onClose={closeErrorSB}
                            close={closeErrorSB}
                            bgWhite
                        />
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" label="Vorname" value={pageState.firstName} onChange={handleFirstNameChange} variant="standard" required fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" label="Nachname" value={pageState.lastName} onChange={handleLastNameChange} variant="standard" required fullWidth />
                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="email" placeholder="Email" label="Email" value={pageState.email} onChange={handleEmailChange} variant="standard" fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput
                                    label="Geburtsdatum" InputLabelProps={{ shrink: true }} type="date"
                                    value={moment(pageState.birthdate).format("yyyy-MM-DD") || ''}
                                    onChange={(e) => {
                                        setPageState(x => ({ ...x, birthdate: e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD") }));
                                    }}
                                    variant="standard" fullWidth
                                />
                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" placeholder="Primäres Telefon" label="Primäres Telefon" value={pageState.primaryPhone} onChange={handlePrimaryPhoneChange} variant="standard" fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" placeholder="Sekundäres Telefon" label="Sekundäres Telefon" value={pageState.secondaryPhone} onChange={handleSecondaryPhoneChange} variant="standard" fullWidth />
                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" placeholder="Stadt" label="Stadt" value={pageState.city} onChange={handleCityChange} variant="standard" fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" placeholder="Straße" label="Straße" value={pageState.street} onChange={handleStreetChange} variant="standard" fullWidth />
                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" placeholder="Postleitzahl" label="Postleitzahl" value={pageState.postalCode} onChange={handlePostalCodeChange} variant="standard" fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                            </Grid>
                        </Grid>
                    </PPBox>
                </form>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
                            <Icon>cancel</Icon>&nbsp;Schließen
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        {
                            pageState.id !== null && pageState.id !== '' ?
                                <PPButton variant="gradient" color="error" onClick={handleDeleteClick} fullWidth>
                                    <Icon>delete</Icon>&nbsp;Löschen
                                </PPButton> : null
                        }
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="primary" type="submit" form="formManagePatient" fullWidth>
                            <Icon>save</Icon>&nbsp;Speichern
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmAddingPatientWithSameName}
                message={t("confirmAddingPatientWithSameNameMsg")}
            />
            <Spinner open={openSpinnerPanel} />
        </Dialog>
    );
}

export default PatientEditor;