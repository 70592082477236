import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import { appEndPoints } from "appSettings";
import { getCurrentUser, getRequestOptions } from "controls/Helpers";
import DataTable from "controls/Tables/DataTable";
import EventCategoryEditor from './EventCategoryEditor';

function EventCategoryListing() {

    const [pageState, setPageState] = useState({
        viewMode: "view",
        selectedCategoryId: '',
        currentUser: getCurrentUser(),
        isLoading: false,
        data: [],
        rows: [],
        tabIndex: 0,
    });
    const [dialogEditorOpen, setDialogEditorOpen] = useState(false);

    const handleEditClick = (arg) => {
        setPageState(old => ({ ...old, selectedCategoryId: arg }));
        setDialogEditorOpen(true);
    };

    const handleOpenEditorDialog = () => {
        setPageState(old => ({ ...old, selectedCategoryId: '' }));
        setDialogEditorOpen(true);
    };

    const columns = [
        { Header: "Name", accessor: "name", width: "25%", align: "left" },
        { Header: "Dauer (Minuten)", accessor: "duration", align: "left" },
        { Header: "Preis (€)", accessor: "rate", align: "center" },
        { Header: "Aktion", accessor: "action", align: "center" },
    ];

    let rows =
        pageState.data.map((e, index) => (
            {
                name: (
                    <PPTypography variant="button" fontWeight="light" color="text">
                        {e.name}
                    </PPTypography>
                ),
                duration: (
                    <PPTypography variant="button" fontWeight="light" color="text" style={{ display: 'block', whiteSpace: 'normal', maxWidth: '250px' }}>
                        {e.duration}
                    </PPTypography>
                ),
                rate: (
                    <PPTypography variant="button" fontWeight="light" color="text">
                        {e.rate}
                    </PPTypography>
                ),
                action: (
                    <PPTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <PPButton variant="outlined" color="primary" size="small" onClick={() => handleEditClick(e.id)}>
                            <Icon>edit</Icon>&nbsp;Bearbeiten
                        </PPButton>
                    </PPTypography>
                ),
            }
        ));

    const handleCloseEditorDialog = () => {
        setDialogEditorOpen(false);
    };

    const handleRefreshListing = () => {
        fetchGridData();
    };

    function fetchGridData() {
        setPageState(old => ({ ...old, isLoading: true }));
        var fetchURL = appEndPoints.getEventCategoriesEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(fetchURL, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                setPageState(old => ({ ...old, isLoading: false, data: resp, rows: resp }));
            })
            .catch(error => { console.log('error', error); setPageState(old => ({ ...old, isLoading: false })); })
    }

    useEffect(() => {
        fetchGridData();
    }, [])

    return (
        <PPBox>
            <PPBox p={2}>
                <Grid container>
                    <Grid item xs={6} md={6} lg={6} textAlign="left">
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} textAlign="right">
                        <PPButton color="primary" onClick={handleOpenEditorDialog}>
                            <Icon>add_circle</Icon>&nbsp;
                            Hinzufügen
                        </PPButton>
                    </Grid>
                </Grid>
            </PPBox>
            <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
                handleEditClick={handleEditClick}
            />
            <EventCategoryEditor categoryId={pageState.selectedCategoryId} open={dialogEditorOpen} handleCloseDialog={handleCloseEditorDialog} handleRefreshListing={handleRefreshListing} />
        </PPBox>
    );
}

export default EventCategoryListing;
