import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import SidenavCollapse from "controls/Sidenav/SidenavCollapse";
import SidenavRoot from "controls/Sidenav/SidenavRoot";
import sidenavLogoLabel from "controls/Sidenav/styles/sidenav";
import {
  usePraxiProUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import UserFeedback from "controls/UserFeedback";
import { getCurrentUser, userHasRole, ROLES } from "controls/Helpers";
import { useTranslation } from 'react-i18next';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { t } = useTranslation();
  const [controller, dispatch] = usePraxiProUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location?.pathname?.replace("/", "");
  const [dialogUserFeedbackOpen, setDialogUserFeedbackOpen] = useState(false);
  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const handleOpenUserFeedbackDialog = () => {
    setDialogUserFeedbackOpen(true);
  }

  const handleCloseUserFeedbackDialog = () => {
    setDialogUserFeedbackOpen(false);
  };

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;

    if (key === 'users' && !userHasRole(ROLES.ADMINISTRATOR, getCurrentUser().roles)) {
    }
    else if (key === 'settings' && !userHasRole(ROLES.ADMINISTRATOR, getCurrentUser().roles)) {
    }
    else {
      if (type === "collapse") {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
            />
          </Link>
        ) : (
          <NavLink key={key} to={route}>
            <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
          </NavLink>
        );
      } else if (type === "title") {
        returnValue = (
          <PPTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </PPTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
    }
    return returnValue;
  });

  return (
    <SidenavRoot className="sideNav"
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <PPBox pt={2} px={4} textAlign="center">
        <PPBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <PPTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </PPTypography>
        </PPBox>
        <PPBox component={NavLink} to="/dashboard" display="flex" alignItems="center">
          {brand && <PPBox component="img" src={brand} alt="Brand" width="2rem" />}
          <PPBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <PPTypography component="h2" fontWeight="medium" color={textColor}>
              {brandName}
            </PPTypography>
          </PPBox>
        </PPBox>
      </PPBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <PPBox p={2} mt="auto">
        <PPButton color={sidenavColor} onClick={handleOpenUserFeedbackDialog} fullWidth>
          <Icon>report_problem</Icon>&nbsp;{t("submitYourFeedback")}
        </PPButton>
        <UserFeedback open={dialogUserFeedbackOpen} handleCloseDialog={handleCloseUserFeedbackDialog} />
      </PPBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "primary",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
