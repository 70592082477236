import { useState } from "react";
import Card from "@mui/material/Card";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPInput from "components/PPInput";
import PPButton from "components/PPButton";
import BasicLayout from "pages/account/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import logoImage from "assets/images/logos/praxipro-logo.png";
import { Icon, Divider, Link, Select, MenuItem } from "@mui/material";
import { appEndPoints } from "appSettings";
import Spinner from "controls/Spinner";
import { setPageTitle } from "controls/Helpers";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
    const { t } = useTranslation();
    setPageTitle(t('forgotMyPassword'));
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [invalidPractice, setInvalidPractice] = useState(false);
    const [selectedPracticeId, setSelectedPracticeId] = useState('00000000-0000-0000-0000-000000000000');
    const [practices, setPractices] = useState([]);
    const [forgotEmailSent, setForgotEmailSent] = useState(false);

    const handleForgotPasswordFormSubmit = (e) => {
        e.preventDefault();
        setInvalidPractice(false);
        setOpenSpinnerPanel(true);
        const forgotPasswordForm = document.getElementById("formForgotPassword");
        const formData = new FormData(forgotPasswordForm);
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        var requestOptions = {
            method: 'POST',
            body: JSON.stringify(object),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        var serviceUrl = appEndPoints.postForgotPasswordEndpoint.replace("{hostname}", appEndPoints.hostname);
        var status = 200;
        fetch(serviceUrl, requestOptions)
            .then(resp => {
                status = resp.status;
                return resp.json();
            })
            .then(resp => {
                setOpenSpinnerPanel(false);
                if (status === 409) {
                    setPractices(resp.practiceData);
                    setInvalidPractice(true);
                }
                else if (status === 401) {
                    //setInvalidCredentials(true);
                }
                else if (status === 200) {
                    setForgotEmailSent(true);
                }
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    return (
        <BasicLayout image={bgImage}>
            <Helmet>
                <link rel="canonical" href="https://app.praxipro.de/forgot-password" />
            </Helmet>
            <Card>
                <PPBox mx={2} p={2} textAlign="center">
                    <a href="https://www.praxipro.de" target="_blank" rel="noreferrer">
                        <PPBox component="img" src={logoImage} alt="" width="60%" mt={1} />
                    </a>
                    <Divider />
                    <PPTypography variant="h5" fontWeight="medium" color="dark" mt={1}>
                        {t("forgotMyPassword")}
                    </PPTypography>
                </PPBox>
                <PPBox pb={3} px={3}>
                    {
                        invalidPractice ?
                            <PPTypography variant="button" color="error" fontWeight="medium" textGradient>
                                {t('selectPractice')}
                            </PPTypography> : null
                    }
                    {
                        forgotEmailSent ?
                            <PPTypography variant="button" color="text">
                                {t("forgotEmailSent")}
                            </PPTypography> : <PPBox pt={2} component="form" role="form" method="POST" id="formForgotPassword" name="formForgotPassword" onSubmit={handleForgotPasswordFormSubmit}>
                                <PPBox mb={2}>
                                    <PPInput type="email" label={t('email')} name="email" autoComplete="email" fullWidth required />
                                </PPBox>
                                {
                                    practices.length > 1 ? <PPBox mt={2}>
                                        <Select className='selectList' fullWidth value={selectedPracticeId}
                                            onChange={(e, newInputValue) => {
                                                setSelectedPracticeId(newInputValue.props.value);
                                            }}>
                                            <MenuItem key='00000000-0000-0000-0000-000000000000' value='00000000-0000-0000-0000-000000000000'>{t("choosePractice")}</MenuItem>
                                            {
                                                practices.map(item => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <input type="hidden" id="hfSelectedPracticeId" name="practiceId" value={selectedPracticeId} />
                                    </PPBox> : null
                                }
                                <PPBox mt={4} mb={1}>
                                    <PPButton variant="gradient" color="primary" type="submit" form="formForgotPassword" fullWidth>
                                        <Icon>publish</Icon>&nbsp;{t("submit")}
                                    </PPButton>
                                </PPBox>
                            </PPBox>
                    }
                    <PPBox mt={3} mb={1} textAlign="center">
                        <PPTypography variant="button" color="text">
                            {t("rememberYourPassword")}&nbsp;
                            <Link href="/sign-in" >
                                <PPTypography
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient>
                                    {t("signin")}
                                </PPTypography>
                            </Link>
                        </PPTypography>
                    </PPBox>
                    <Spinner open={openSpinnerPanel} text="Wir bestätigen Ihr Benutzerkonto." />
                </PPBox>
            </Card>
        </BasicLayout >
    );
}

export default ForgotPassword;
