import React, { useState, useEffect } from 'react'
import Card from "@mui/material/Card";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPTypography from "components/PPTypography";
import Note from "pages/notes/components/Note"
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import NoRecords from "controls/NoRecords";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NotesPreview() {
  const { t } = useTranslation();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    totalRecords: 0,
    currentPage: 1,
    pageSize: 5,
    searchQuery: '',
  });

  function fetchGridData(q) {
    setPageState(old => ({ ...old, isLoading: true }));
    var fetchURL = appEndPoints.searchNotesEndpoint.replace("{hostname}", appEndPoints.hostname).replace("{pageNumber}", pageState.currentPage).replace("{pageSize}", pageState.pageSize) + "?q=";
    fetch(fetchURL, getRequestOptions())
      .then(resp => resp.json())
      .then(resp => {
        let currentData = resp.notes;
        if (pageState.currentPage > 1) {
          currentData = pageState.data;
          resp.notes.map((e, index) => (
            currentData.push(e)
          ))
        }
        //Todo: set totalRecords
        setPageState(old => ({ ...old, isLoading: false, data: currentData, totalRecords: resp.totalRecords }));
      })
      .catch(error => { console.log('error', error); setPageState(old => ({ ...old, isLoading: false })); })
  }

  useEffect(() => {
    fetchGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card sx={{ height: "100%" }}>
      <PPBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <PPTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {t("allNotes")}
        </PPTypography>
        <PPBox display="flex" alignItems="flex-start">
          <PPButton component={Link} to="/notes" variant="outlined" color="info" size="small">
            Alle ansehen
          </PPButton>
        </PPBox>
      </PPBox>
      <PPBox pt={3} pb={3} px={2}>
        <PPBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {
            pageState.data.map((i, j) => (
              <Note key={j} content={i.content}
              />
            ))
          }
          {
            (pageState.data === null || pageState.data.length === 0) && !pageState.isLoading ?
              <NoRecords />
              : null
          }
        </PPBox>
      </PPBox>
    </Card>
  );
}

export default NotesPreview;
