import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import timelineItem from "controls/Timeline/TimelineItem/styles";
import PPButton from "components/PPButton";
import moment from 'moment'
import Grid from "@mui/material/Grid";
import DocumentationFilesList from "./DocumentationFilesList";

function DocumentationItem({ id, icon, title, dateTime, description, fileNames, friendlyFileNames, lastItem, patientId, handleEditClick, handleDeleteClick }) {
  const color = "info";
  const isDark = false;

  const handleEditDocumentationClick = (e) => {
    e.preventDefault();
    handleEditClick(id);
  };

  // const handleCopyDocumentationClick = (e) => {
  //   e.preventDefault();
  //   if ('clipboard' in navigator) {
  //     return navigator.clipboard.writeText(description);
  //   } else {
  //     return document.execCommand('copy', true, description);
  //   }
  // };

  const handleDeleteDocumentationClick = (e) => {
    e.preventDefault();
    handleDeleteClick(id);
  };

  return (
    <PPBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <PPBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="4%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </PPBox>
      <PPBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <PPTypography variant="button" fontWeight="medium" color="dark">
              {title}
            </PPTypography><br />
            <PPTypography variant="caption" color="text">
              <Icon>event</Icon> {moment(dateTime).format("DD.MM.yyyy")}
            </PPTypography>
          </Grid>
          <Grid item xs={12} md={8} lg={8} style={{ textAlign: "right" }}>
            {/* <PPButton variant="outlined" color="primary" onClick={handleCopyDocumentationClick}>
              <Icon>content_copy</Icon>
            </PPButton> */}
            <PPButton style={{ marginLeft: "10px" }} variant="outlined" color="primary" onClick={handleEditDocumentationClick}>
              <Icon>edit</Icon>
            </PPButton>
            <PPButton style={{ marginLeft: "10px" }} variant="outlined" color="error" onClick={handleDeleteDocumentationClick}>
              <Icon>delete</Icon>
            </PPButton>
          </Grid>
        </Grid>
        <PPBox mt={2} mb={1.5}>
          {description ? (
            <PPTypography variant="button" color="dark">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </PPTypography>
          ) : null}
        </PPBox>
        <DocumentationFilesList id={id} patientId={patientId} fileNames={fileNames} friendlyFileNames={friendlyFileNames} viewMode="View" />
      </PPBox>
    </PPBox >
  );
}

// Setting default values for the props of TimelineItem
DocumentationItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
DocumentationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default DocumentationItem;
