import PPBox from "components/PPBox";
import React, { useState, useEffect, forwardRef } from 'react'
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import DocumentationItem from "./DocumentationItem";
import NoRecords from "controls/NoRecords";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';

const DocumentationList = forwardRef((settings, ref) => {
    const { t } = useTranslation();
    const [pageState, setPageState] = useState({
        renderDocumentationList: [],
        selectedItemId: ''
    });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleEditClick = (e) => {
        settings.handleEditClick(e);
    };

    const handleDeleteClick = (e) => {
        setPageState(old => ({ ...old, selectedItemId: e }));
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        var body = JSON.stringify({
            "id": pageState.selectedItemId,
            "patientId": settings.patientId,
        });
        var serviceUrl = appEndPoints.documentationsEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                fetchDocumentation();
            })
            .catch(error => { console.log('error', error); });
        // Close the confirm dialog after the action is performed
        setConfirmDialogOpen(false);
    };

    function fetchDocumentation() {
        if (settings.patientId !== null && settings.patientId !== '' && settings.patientId !== undefined) {
            var serviceUrl = appEndPoints.getDocumentationsByPatientEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + settings.patientId;
            fetch(serviceUrl, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    let list = [];
                    resp.map((item, index) => {
                        return list.push(
                            <DocumentationItem
                                key={item.id}
                                id={item.id}
                                icon="notifications"
                                title={item.createdByUser.firstName + ' ' + item.createdByUser.lastName}
                                description={item.notes}
                                dateTime={item.documentationDate}
                                patientId={settings.patientId}
                                fileNames={item.fileNames}
                                friendlyFileNames={item.friendlyFileNames}
                                handleEditClick={handleEditClick}
                                handleDeleteClick={handleDeleteClick}
                                lastItem={index === resp.length - 1}
                            />
                        );
                    });
                    setPageState(x => ({ ...x, renderDocumentationList: list }));
                })
                .catch(error => { console.log('error', error); })
        }
    }

    useEffect(() => {
        fetchDocumentation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.patientId]);

    return (
        <PPBox>
            {
                pageState.renderDocumentationList.length > 0 ? pageState.renderDocumentationList :
                    <NoRecords />
            }
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                message={t("confirmDeleteDocumentationMsg")}
            />
        </PPBox>
    )
});

export default DocumentationList;