import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";

function AccessDenied() {
    return (
        <PPBox style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
            <PPTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                Zugriff abgelehnt
            </PPTypography>
        </PPBox>
    );
}

export default AccessDenied;