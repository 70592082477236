
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";

function ComplexStatisticsCard({ color, title, count, unitOfMeasure, percentage, icon }) {
  return (
    <Card className="statisticsCard">
      <PPBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <PPBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </PPBox>
        <PPBox textAlign="right" lineHeight={1.25} className="cardTitle">
          <PPTypography variant="button" fontWeight="light" color="text">
            {title}
          </PPTypography>
          <PPTypography variant="h4">{count}&nbsp;<span style={{fontSize:"1rem"}}>{unitOfMeasure}</span></PPTypography>
        </PPBox>
      </PPBox>
      <Divider />
      <PPBox pb={2} px={2} className="cardFooter">
        <PPTypography component="p" variant="button" color="text" display="flex">
          <PPTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={percentage.color}
          >
            {percentage.amount}
          </PPTypography>
          &nbsp;{percentage.label}
        </PPTypography>
      </PPBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
