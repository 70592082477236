import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Spinner({ open, text }) {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}>
            <CircularProgress color="inherit" />
            <span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>{text !== undefined && text !== '' ? text : "Bitte warten..."}</span>
        </Backdrop>
    );
}

export default Spinner;