import { useState } from "react";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPInput from "components/PPInput";
import PPButton from "components/PPButton";
import BasicLayout from "pages/account/components/BasicLayout";
import bgImage from "assets/images/bg-sign-up-cover.jpg";
import logoImage from "assets/images/logos/praxipro-logo.png";
import { Divider } from "@mui/material";
import Link from "@mui/material/Link";
import PrivacyPolicy from "controls/Legal/PrivacyPolicy";
import TermsOfService from "controls/Legal/TermsOfService";
import Spinner from "controls/Spinner";
import { appEndPoints } from "appSettings";
import { setPageTitle } from "controls/Helpers";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

function SignUp() {
  const { t } = useTranslation();
  setPageTitle(t('signup'));
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [dialogPrivacyPolicyOpen, setDialogPrivacyPolicyOpen] = useState(false);
  const [dialogTermsOfServiceOpen, setDialogTermsOfServiceOpen] = useState(false);
  const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsErrorMsg, setShowTermsErrorMsg] = useState(false);

  const handleOpenPrivacyPolicyDialog = () => {
    setDialogPrivacyPolicyOpen(true);
  }

  const handleClosePrivacyPolicyDialog = () => {
    setDialogPrivacyPolicyOpen(false);
  };

  const handleOpenTermsOfServiceDialog = () => {
    setDialogTermsOfServiceOpen(true);
  }

  const handleCloseTermsOfServiceDialog = () => {
    setDialogTermsOfServiceOpen(false);
  };

  const handleSignUpFormSubmit = (e) => {
    e.preventDefault();
    setShowTermsErrorMsg(false);
    if (!termsAccepted) {
      setShowTermsErrorMsg(true);
      return;
    }
    setOpenSpinnerPanel(true);
    // Access the form element...
    const signUpForm = document.getElementById("formSignUp");
    // Bind the FormData object and the form element
    const formData = new FormData(signUpForm);
    var object = {};
    formData.forEach((value, key) => object[key] = value);
    var requestOptions = {
      method: 'POST',
      body: JSON.stringify(object),
      headers: {
        'Content-Type': 'application/json'
      }
    };
    var serviceUrl = appEndPoints.postSignupEndpoint.replace("{hostname}", appEndPoints.hostname);
    fetch(serviceUrl, requestOptions)
      .then(resp => {
        return resp.json();
      })
      .then(resp => {
        setOpenSpinnerPanel(false);
        setAlertDialogOpen(true);
      })
      .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
  };

  return (
    <BasicLayout image={bgImage}>
      <Helmet>
        <link rel="canonical" href="https://app.praxipro.de/sign-up" />
      </Helmet>
      <Card>
        <PPBox mx={2} p={1} textAlign="center">
          <a href="https://www.praxipro.de" target="_blank" rel="noreferrer">
            <PPBox component="img" src={logoImage} alt="" width="171px" height="68px" mt={1} />
          </a>
          <Divider />
          <PPTypography variant="h4" fontWeight="medium" color="dark" mt={1}>
            {t("getStartedWithPraxiPro")}
          </PPTypography>
          <PPTypography display="block" variant="button" color="dark" my={1}>
            {t("signupInto")}
          </PPTypography>
        </PPBox>
        <PPBox pb={3} px={3}>
          {
            !alertDialogOpen ?
              <PPBox component="form" role="form" method="POST" id="formSignUp" name="formSignUp" onSubmit={handleSignUpFormSubmit}>
                <PPBox display="flex" alignItems="center" mb={2}>
                  <PPInput type="text" label={t("firstName")} name="firstName" variant="standard" required fullWidth />
                  &nbsp;&nbsp;&nbsp;
                  <PPInput type="text" label={t("lastName")} name="lastName" variant="standard" required fullWidth />
                </PPBox>
                <PPBox mb={2}>
                  <PPInput type="email" label={t("email")} name="email" variant="standard" required fullWidth />
                </PPBox>
                <PPBox mb={2}>
                  <PPInput type="text" label={t("choosePracticeName")} name="practiceName" variant="standard" required fullWidth />
                </PPBox>
                <PPBox display="flex" alignItems="center" ml={-1} >
                  <Switch color="primary" checked={termsAccepted} onChange={(e, newValue) => { setShowTermsErrorMsg(false); setTermsAccepted(newValue); }} />
                  <PPTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }} className="signupTC">
                    {t("acceptTerms")}&nbsp;
                    <Link href="#" onClick={handleOpenTermsOfServiceDialog}>
                      <PPTypography
                        variant="button"
                        color="info"
                        fontWeight="medium">
                        {t("termsOfService")}
                      </PPTypography>
                    </Link>, {t("and")}&nbsp;
                    <Link href="#" onClick={handleOpenPrivacyPolicyDialog}>
                      <PPTypography
                        variant="button"
                        color="info"
                        fontWeight="medium">
                        {t("privacyPolicy")}
                      </PPTypography>
                    </Link>
                    <PrivacyPolicy open={dialogPrivacyPolicyOpen} handleCloseDialog={handleClosePrivacyPolicyDialog} />
                    <TermsOfService open={dialogTermsOfServiceOpen} handleCloseDialog={handleCloseTermsOfServiceDialog} />
                  </PPTypography>
                </PPBox>
                {
                  showTermsErrorMsg ?
                    <PPTypography variant="button" color="error" fontWeight="medium" textGradient>
                      {t("termsConsentRequired")}
                    </PPTypography> : null
                }
                <PPBox mt={4} mb={1}>
                  <PPButton variant="gradient" color="primary" type="submit" form="formSignUp" fullWidth>
                    {t("signup")}
                  </PPButton>
                </PPBox>
              </PPBox> : null
          }
          {
            alertDialogOpen ?
              <PPTypography variant="button" color="text" fontWeight="light">
                {t("signUpFormSubmittedMsg")}
              </PPTypography> : null
          }
          <PPBox mt={3} mb={1} textAlign="center">
            <PPTypography variant="button" color="text">
              {t("alreadyHaveAccount")}&nbsp;
              <Link href="/sign-in" >
                <PPTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient>
                  {t("signin")}
                </PPTypography>
              </Link>
            </PPTypography>
          </PPBox>
        </PPBox>
        <Spinner open={openSpinnerPanel} text="Lädt..." />
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
