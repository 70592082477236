export const appEndPoints = {
    //hostname: "https://localhost:7043",
    hostname: "https://praxiproapi.azurewebsites.net",
    //hostname: "https://9232bf87c41e8ab99bb48c11784f98f4.serveo.net",
    //TODO: review and fix repetetive endpoints url for get/post/put/delete...

    leaveRequestsEndpoint: "{hostname}/v1/leave-requests",
    documentationsEndpoint: "{hostname}/v1/documentations",
    practiceHolidaysEndpoint: "{hostname}/v1/practice-holidays",
    templateEndpoint: "{hostname}/v1/templates",
    publicHolidaysEndpoint: "{hostname}/v1/public-holidays",
    userWeeklyHoursEndpoint: "{hostname}/v1/user-weekly-hours",

    searchPatientsEndpoint: "{hostname}/v1/patients/{pageNumber}/{pageSize}",
    getPatientsEndpoint: "{hostname}/v1/patients",
    getAllEventsEndpoint: "{hostname}/v1/events/users/{userId}/{userState}/{fromDate}",
    getEventDetailsEndpoint: "{hostname}/v1/events",
    getDocumentationsByPatientEndpoint: "{hostname}/v1/documentations/patients",
    getEventCategoriesEndpoint: "{hostname}/v1/event-categories",
    getUsersEndpoint: "{hostname}/v1/users",
    getUserRolesEndpoint: "{hostname}/v1/user-roles/{userId}",

    getLeaveRequestsListingEndpoint: "{hostname}/v1/leave-requests/{userId}/{status}/{pageNumber}/{pageSize}",
    getTimeTrackingByMonthEndpoint: "{hostname}/v1/time-tracking/{userId}/{month}/{year}",
    getTimeTrackingByYearEndpoint: "{hostname}/v1/time-tracking/{userId}/{year}",
    getTimeTrackingByYearAllEndpoint: "{hostname}/v1/time-tracking/{userId}/{year}/all",
    getTimeTrackingYearsEndpoint: "{hostname}/v1/time-tracking/years",
    getFileBinary: "{hostname}/v1/file-handler/{id}/{fileName}",
    getSettingsEndpoint: "{hostname}/v1/settings",
    searchNotesEndpoint: "{hostname}/v1/notes/{pageNumber}/{pageSize}",
    getNotesEndpoint: "{hostname}/v1/notes",
    searchFeedbackEndpoint: "{hostname}/v1/feedback/{pageNumber}/{pageSize}",
    getFeedbackEndpoint: "{hostname}/v1/feedback",

    postAuthenticateEndpoint: "{hostname}/v1/account/authenticate",
    postSignupEndpoint: "{hostname}/v1/account/sign-up",
    postEventEndpoint: "{hostname}/v1/events",
    postEventInstanceStatusEndpoint: "{hostname}/v1/event-instances/update-status",
    postPatientEndpoint: "{hostname}/v1/patients",
    postLeaveRequestEndpoint: "{hostname}/v1/leave-requests",
    postUserEndpoint: "{hostname}/v1/users",
    postSettingEndpoint: "{hostname}/v1/settings",
    postNoteEndpoint: "{hostname}/v1/notes",
    postEventCategoriesEndpoint: "{hostname}/v1/event-categories",
    postFeedbackEndpoint: "{hostname}/v1/feedback",
    postForgotPasswordEndpoint: "{hostname}/v1/account/forgot-password",

    putEventEndpoint: "{hostname}/v1/events",
    putPatientEndpoint: "{hostname}/v1/patients",
    putLeaveRequestEndpoint: "{hostname}/v1/leave-requests",
    putUserEndpoint: "{hostname}/v1/users",
    putMyProfileEndpoint: "{hostname}/v1/users/my-profile",
    putMyPreferencesEndpoint: "{hostname}/v1/users/my-preferences",
    putSettingEndpoint: "{hostname}/v1/settings",
    putNoteEndpoint: "{hostname}/v1/notes",
    putEventCategoriesEndpoint: "{hostname}/v1/event-categories",
    putFeedbackEndpoint: "{hostname}/v1/feedback",

    patchUserPasswordEndpoint: "{hostname}/v1/users/change-password",
    patchUserStatusEndpoint: "{hostname}/v1/users/change-status",
    patchConfirmRegistrationEndpoint: "{hostname}/v1/account/confirm-registration",
    patchResetPasswordEndpoint: "{hostname}/v1/account/reset-password",

    deleteEventEndpoint: "{hostname}/v1/events",
    deleteDocumentationFileEndpoint: "{hostname}/v1/documentations/files",
    deletePatientEndpoint: "{hostname}/v1/patients",
    deleteUserEndpoint: "{hostname}/v1/users",
    deleteSettingEndpoint: "{hostname}/v1/settings",
    deleteNoteEndpoint: "{hostname}/v1/notes",
    deleteEventCategoriesEndpoint: "{hostname}/v1/event-categories",
    deleteFeedbackEndpoint: "{hostname}/v1/feedback",
}