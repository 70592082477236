import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";

function NoRecords() {
    return (
        <PPBox style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
            <PPTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                Keine Datensätze zum Anzeigen
            </PPTypography>
        </PPBox>
    );
}

export default NoRecords;