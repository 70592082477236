import { useState, useEffect } from 'react'
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPInput from "components/PPInput";
import moment from "moment";

function InlineEditor({ fieldName, fieldValue, isRequired, type, viewMode }) {

    const [currentValue, setCurrentValue] = useState(fieldValue);
    const [dateValue, setDateValue] = useState('null');

    useEffect(() => {
        try {
            if (type === "date") {
                var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
                var dt = new Date(fieldValue.replace(pattern, '$3-$2-$1'));
                setDateValue(moment(dt).format("yyyy-MM-DD"));
            }
        }
        catch (e) {
            console.log(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PPBox py={1} pr={2}>
            {
                (viewMode === "edit") ?
                    (type === "text") ?
                        <PPBox mb={2}>
                            <PPInput type="text" name={fieldName} value={currentValue ?? ""} label="" placeholder="" variant="standard" required={isRequired} fullWidth onChange={(e) => setCurrentValue(e.target.value)} />
                        </PPBox> :
                        (type === "dropdown") ?
                            <PPBox mb={2}>
                                {/* <Autocomplete
                                    disablePortal
                                    name={fieldName}
                                    getOptionLabel={(option) => option.name}
                                    options={lkpValues}
                                    value={selectedItem}
                                    onChange={(event, value) => { if (value != null) { setSelectedItem(value) } }}
                                    renderInput={(params) => <TextField {...params} name={fieldName} fullWidth variant="standard" required={isRequired} />}
                                /> */}
                            </PPBox>
                            :
                            (type === "date") ?
                                <PPBox mb={2}>
                                    <PPInput id="birthdate" name="birthdate" InputLabelProps={{ shrink: true }} type="date"
                                    value={dateValue || ''}
                                    onChange={(e) => {
                                        setDateValue(moment(e.target.value).format("yyyy-MM-DD"));
                                    }}
                                    variant="standard" fullWidth required={isRequired} />
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            inputFormat="dd.MM.yyyy"
                                            value={dateValue}
                                            onChange={(newValue) => {
                                                setDateValue(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} id="birthdate" name="birthdate" value={dateValue} variant="standard" fullWidth required={isRequired} />}
                                        />
                                    </LocalizationProvider> */}
                                </PPBox>
                                : null
                    :
                    <PPTypography variant="button" fontWeight="regular" color="text">
                        {fieldValue ? fieldValue : "-"}
                    </PPTypography>
            }
        </PPBox>
    );
}

export default InlineEditor;