import React, { useRef, useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import LeaveRequestEditor from "./components/LeaveRequestEditor";
import ApprovalEditor from "./components/ApprovalEditor";
import LeaveRequestsListing from "./components/LeaveRequestsListing";
import PublicHolidays from "./components/PublicHolidays";
import PracticeHolidays from "./components/PracticeHolidays";
import { appEndPoints } from "appSettings";
import { getRequestOptions, getCurrentUserId, getCurrentUser, userHasAccess, ROLES } from "controls/Helpers";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import { setPageTitle } from "controls/Helpers";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import { useTranslation } from 'react-i18next';


function Leaves() {
  const { t } = useTranslation();
  setPageTitle(t('leaves'));
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    totalRecords: 0,
    currentPage: 1,
    pageSize: 25,
    hideLoadMore: false,
    selectedLeaveRequestId: null,
    searchQuery: '',
    newStatus: 'Approve',
    isLeaveRequestManager: userHasAccess(ROLES.LEAVE_REQUEST_MANAGER, getCurrentUser().roles),
  });
  const [selectedUserId, setSelectedUserId] = useState(getCurrentUserId());
  const [users, setUsers] = useState([]);
  const leaveRequestsGridRef = useRef();
  const [dialogEditorOpen, setDialogEditorOpen] = useState(false);
  const [dialogApprovalEditorOpen, setDialogApprovalEditorOpen] = useState(false);
  //const [selectedUserLeavesViewId, setSelectedUserLeavesViewId] = useState(pageState.isLeaveRequestManager ? 1 : 2);
  const [selectedLeavesStatusViewId, setSelectedLeavesStatusViewId] = useState(pageState.isLeaveRequestManager ? 0 : 1);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const handleTabsChange = (event, newIndex) => { setTabIndex(newIndex); };

  const handleApprovalClick = (arg, status) => {
    setPageState(old => ({ ...old, selectedLeaveRequestId: arg, newStatus: status }));
    setDialogApprovalEditorOpen(true);
  };

  const handleEditClick = (arg) => {
    setPageState(old => ({ ...old, selectedLeaveRequestId: arg }));
    setDialogEditorOpen(true);
  };

  const handleDeleteClick = (arg) => {
    setPageState(old => ({ ...old, selectedLeaveRequestId: arg }));
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    var body = JSON.stringify({
      "id": pageState.selectedLeaveRequestId,
    });
    var serviceUrl = appEndPoints.leaveRequestsEndpoint.replace("{hostname}", appEndPoints.hostname);

    fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
      .then(resp => resp.json())
      .then(resp => {
        handleRefreshListing();
      })
      .catch(error => { console.log('error', error); })
  };

  const handleUserSelectionChange = (e) => {
    document.getElementById('hfSelectedUserId').value = e.target.value;
    setSelectedUserId(e.target.value, leaveRequestsGridRef.current.refreshListing());
  }

  // const handleUserLeavesViewChange = (e) => {
  //   if (e.target.value === "2") {
  //     document.getElementById('hfSelectedUserLeavesViewId').value = e.target.value;
  //     setSelectedUserLeavesViewId(e.target.value);

  //     document.getElementById('hfSelectedLeavesStatusViewId').value = "1";
  //     setSelectedLeavesStatusViewId(1, leaveRequestsGridRef.current.refreshListing());
  //   }
  //   else {
  //     document.getElementById('hfSelectedUserLeavesViewId').value = e.target.value;
  //     setSelectedUserLeavesViewId(e.target.value, leaveRequestsGridRef.current.refreshListing());

  //     document.getElementById('hfSelectedLeavesStatusViewId').value = "0";
  //     setSelectedLeavesStatusViewId(0, leaveRequestsGridRef.current.refreshListing());
  //   }
  // }

  const handleLeavesStatusViewChange = (e) => {
    document.getElementById('hfSelectedLeavesStatusViewId').value = e.target.value;
    setSelectedLeavesStatusViewId(e.target.value, leaveRequestsGridRef.current.refreshListing());
  }

  const handleOpenEditorDialog = () => {
    setPageState(old => ({ ...old, selectedLeaveRequestId: '' }));
    setDialogEditorOpen(true);
  };

  const handleCloseEditorDialog = () => {
    setPageState(old => ({ ...old, selectedLeaveRequestId: '' }));
    setDialogEditorOpen(false);
  };

  const handleCloseApprovalEditorDialog = () => {
    setPageState(old => ({ ...old, selectedLeaveRequestId: '' }));
    setDialogApprovalEditorOpen(false);
  };

  const handleRefreshListing = () => {
    leaveRequestsGridRef.current.refreshListing();
  };

  function fetchUsers() {
    var serviceUrl = appEndPoints.getUsersEndpoint.replace("{hostname}", appEndPoints.hostname);
    fetch(serviceUrl, getRequestOptions())
      .then(resp => resp.json())
      .then(resp => {
        var elements = [];
        for (var i = 0; i < resp.length; i++) {
          elements.push({
            value: resp[i].id,
            label: resp[i].firstName + ' ' + resp[i].lastName,
          });
        }
        setUsers(elements);
      })
  }

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrivateLayout>
      <PPBox pb={2}>
        <Card>
          <PPBox p={2}>
            <Grid container spacing={3} alignItems="center">
              <Grid item>
              </Grid>
              <Grid item xs={12} md={8} lg={8} sx={{ ml: "auto" }}>
                <AppBar position="static">
                  <Tabs orientation={tabsOrientation} value={tabIndex} onChange={handleTabsChange}>
                    <Tab label="Abwesenheit" icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>luggage</Icon>} />
                    <Tab label="Feiertage" icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>calendar_month</Icon>} />
                    <Tab label="Praxis Schließtage" icon={<Icon fontSize="small" sx={{ mt: -0.25 }}>date_range</Icon>} />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </PPBox>
        </Card>
      </PPBox>
      <Card>
        {
          tabIndex === 0 ? <div>
            <PPBox p={2}>
              <Grid container>
                <Grid item xs={12} md={8} lg={8}>
                  {
                    pageState.isLeaveRequestManager &&
                      <span>
                        {t('employee')}
                        {/* <Select value={selectedUserLeavesViewId} onChange={handleUserLeavesViewChange} className='selectList' sx={{ m: 1, minWidth: 200, maxHeight: 35 }}>
                          <MenuItem key="1" value="1">{t('manageVacationRequests')}</MenuItem>
                          <MenuItem key="2" value="2">{t('myVacationRequests')}</MenuItem>
                        </Select> */}
                        <Select value={selectedUserId} onChange={handleUserSelectionChange} className='selectList' sx={{ m: 1, minWidth: 160, maxHeight: 35 }}>
                          {users.map(item => {
                            return (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>);
                          })}
                        </Select>
                      </span>
                  }
                  {t('filterByStatus')} <Select value={selectedLeavesStatusViewId} onChange={handleLeavesStatusViewChange} className='selectList' sx={{ m: 1, minWidth: 120, maxHeight: 35 }}>
                    <MenuItem value="0">{t('due')}</MenuItem>
                    <MenuItem value="1">{t('approved')}</MenuItem>
                    <MenuItem value="2">{t('rejected')}</MenuItem>
                  </Select>
                  <input type="hidden" id="hfSelectedUserId" value={selectedUserId} />
                  <input type="hidden" id="hfSelectedLeavesStatusViewId" value={selectedLeavesStatusViewId} />
                </Grid>
                <Grid item xs={12} md={4} lg={4} textAlign="right">
                  <PPButton color="primary" onClick={handleOpenEditorDialog}>
                    <Icon>add_circle</Icon>&nbsp;
                    {t('add')}
                  </PPButton>
                </Grid>
              </Grid>
            </PPBox>
            <PPBox pt={1} px={2}>
              <LeaveRequestEditor leaveRequestId={pageState.selectedLeaveRequestId} open={dialogEditorOpen} handleCloseDialog={handleCloseEditorDialog} handleRefreshListing={handleRefreshListing} />
              <ApprovalEditor leaveRequestId={pageState.selectedLeaveRequestId} newStatus={pageState.newStatus} open={dialogApprovalEditorOpen} handleCloseDialog={handleCloseApprovalEditorDialog} handleRefreshListing={handleRefreshListing} />
              <PPBox pb={2} style={{ minHeight: "500px" }}>
                <LeaveRequestsListing ref={leaveRequestsGridRef}
                  handleSearch={pageState.handleSearch}
                  searchQuery={pageState.searchQuery}
                  selectedLeavesStatusViewId={selectedLeavesStatusViewId}
                  handleApprovalClick={handleApprovalClick}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                  isLeaveRequestManager={pageState.isLeaveRequestManager}
                />
              </PPBox>
              <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                message={t("confirmDeleteLeaveRequestMsg")}
              />
            </PPBox>
          </div> : null
        }
        {
          tabIndex === 1 ? <PublicHolidays /> : null
        }
        {
          tabIndex === 2 ? <PracticeHolidays /> : null
        }
      </Card>
    </PrivateLayout>
  );
}

export default Leaves;
