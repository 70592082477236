import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import { appEndPoints } from "appSettings";
import { getRequestOptions, getCurrentUserId, setPageTitle } from "controls/Helpers";
import Card from "@mui/material/Card";
import PPInput from "components/PPInput";
import Spinner from "controls/Spinner";
import { useTranslation } from 'react-i18next';

function ChangePassword() {
    const { t } = useTranslation();
    setPageTitle(t('changePassword'));
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [invalidPasswords, setInvalidPasswords] = useState(false);
    const [pageState, setPageState] = useState({
        userId: getCurrentUserId(),
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });

    const handleCurrentPasswordChange = (e) => {
        setPageState(x => ({ ...x, currentPassword: e.target.value ? e.target.value : '' }));
    };

    const handleNewPasswordChange = (e) => {
        setPageState(x => ({ ...x, newPassword: e.target.value ? e.target.value : '' }));
    };

    const handleConfirmNewPasswordChange = (e) => {
        setPageState(x => ({ ...x, confirmNewPassword: e.target.value ? e.target.value : '' }));
    };

    const handleChangePasswordSubmit = (e) => {
        e.preventDefault();
        setOpenSpinnerPanel(true);
        setInvalidPasswords(false);
        const myForm = document.getElementById("updatePassword");
        const formData = new FormData(myForm);
        if (pageState.newPassword !== pageState.confirmNewPassword) {
            setInvalidPasswords(true);
            setOpenSpinnerPanel(false);
            return;
        }
        var body = JSON.stringify({
            "id": pageState.userId,
            "currentPassword": formData.get('currentPassword'),
            "newPassword": formData.get('newPassword'),
            "confirmNewPassword": formData.get('confirmNewPassword'),
        });
        var fetchURL = appEndPoints.patchUserPasswordEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('PATCH', body, 'json');
        fetch(fetchURL, requestOptions)
            .then(resp => resp.json())
            .then(resp => {
                //TODO: show notification message that the password was updated successfully
                //Make use of the confirm password value, right now I'm not making use of it to confirm the identity of the requester
                setOpenSpinnerPanel(false);
                setPageState(old => ({ ...old, currentPassword: "", newPassword: "", confirmNewPassword: "" }));
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    return (
        <PrivateLayout>
            <Card>
                <PPBox pt={0} pb={3}>
                    <PPBox pt={3} style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                        <PPBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                            <PPTypography variant="h5" fontWeight="medium" style={{ textTransform: "uppercase" }}>
                                Kennwort ändern
                            </PPTypography>
                        </PPBox>
                        <PPBox p={2}>
                                {
                                    invalidPasswords ?
                                        <PPTypography variant="button" color="error" fontWeight="medium" textGradient>
                                            {t("invalidConfirmPassword")}
                                        </PPTypography> : null
                                }
                                <form id="updatePassword" method="POST" encType="multipart/form-data" name="updatePassword" onSubmit={handleChangePasswordSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4} lg={4}>
                                            <PPBox mb={2}>
                                                <PPInput type="password" name="currentPassword" placeholder="Current Password *" label="Current Password" value={pageState.currentPassword} onChange={handleCurrentPasswordChange} variant="standard" required fullWidth />
                                            </PPBox>
                                            <PPBox mb={2}>
                                                <PPInput type="password" name="newPassword" placeholder="New Password *" label="New Password" value={pageState.newPassword} onChange={handleNewPasswordChange} variant="standard" required fullWidth />
                                            </PPBox>
                                            <PPBox mb={2}>
                                                <PPInput type="password" name="confirmNewPassword" placeholder="Confirm New Password *" label="Confirm New Password" value={pageState.confirmNewPassword} onChange={handleConfirmNewPasswordChange} variant="standard" required fullWidth />
                                            </PPBox>
                                        </Grid>
                                        <PPBox style={{ width: "100%" }}>
                                            <div style={{ float: "right", paddingRight: "10px" }}>
                                                <PPButton color="primary" type="submit" form="updatePassword" fullWidth>
                                                    <Icon>save</Icon>&nbsp;{t("save")}
                                                </PPButton>
                                            </div>
                                        </PPBox>
                                    </Grid>
                                </form>
                            
                        </PPBox>
                        <Spinner open={openSpinnerPanel} text="Speichern bitte warten" />
                    </PPBox>
                </PPBox>
            </Card>
        </PrivateLayout>
    );
}

export default ChangePassword;
