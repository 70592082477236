import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequestOptions, getLeaveRequestStatusAsBadge, getLeaveRequestStatusFromText, getLeaveRequestTypeAsText } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import moment from 'moment';
import PPTypography from "components/PPTypography";
import Spinner from "controls/Spinner";
import { useTranslation } from 'react-i18next';

const ApprovalEditor = (args) => {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        id: args.leaveRequestId,
        leaveType: '',
        startDate: moment(new Date()).format("yyyy-MM-DD"),
        endDate: moment(new Date()).format("yyyy-MM-DD"),
        createdDate: moment(new Date()).format("yyyy-MM-DD"),
        duration: 1,
        status: 0,
        requestedBy: '',
        requestedByUser: '',
        requesterComments: 'n.v.',
        managerComments: 'n.v.',
    });

    const handleManagerCommentsChange = (e) => {
        setPageState(x => ({ ...x, managerComments: e.target.value ? e.target.value : '' }));
    };

    const handleLeaveRequestFormSubmit = (e) => {
        e.preventDefault();
        setOpenSpinnerPanel(true);
        var body = JSON.stringify({
            "id": pageState.id ? pageState.id : null,
            "userId": pageState.requestedBy,
            //TODO: refactor this line of code to avoid static values
            "status": getLeaveRequestStatusFromText(args.newStatus),
            "managerComments": pageState.managerComments ? pageState.managerComments : null,
        });
        var fetchURL = appEndPoints.putLeaveRequestEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('PUT', body, 'json');
        fetch(fetchURL, requestOptions)
            .then(resp => resp.json())
            .then(resp => {
                args.handleRefreshListing();
                handleCloseEditor();
                setOpenSpinnerPanel(false);
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    function fetchLeaveRequestDetails() {
        if (args.leaveRequestId) {
            setOpenSpinnerPanel(true);
            var fetchURL = appEndPoints.leaveRequestsEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + args.leaveRequestId;
            fetch(fetchURL, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, id: resp.id,
                        leaveType: resp.type,
                        status: resp.status,
                        requestedBy: resp.requestedBy,
                        requestedByUser: resp.requestedByUser.firstName + ' ' + resp.requestedByUser.lastName,
                        startDate: resp.startDate ? moment(resp.startDate).format("DD.MM.YYYY") : null,
                        endDate: resp.endDate ? moment(resp.endDate).format("DD.MM.YYYY") : null,
                        createdDate: resp.createdDate ? moment(resp.createdDate).format("DD.MM.YYYY") : null,
                        duration: resp.duration,
                        requesterComments: resp.requesterComments ? resp.requesterComments : 'n.v.',
                        managerComments: resp.managerComments ? resp.managerComments : 'n.v.',
                    }));
                    setOpenSpinnerPanel(false);
                })
                .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
        }
        else {
            resetForm();
        }
    }

    const handleCloseEditor = (e) => {
        resetForm();
        args.handleCloseDialog();
    };

    function resetForm() {
        setPageState(x => ({
            ...x, id: '',
            leaveType: '',
            startDate: moment(new Date()).format("yyyy-MM-DD"),
            endDate: moment(new Date()).format("yyyy-MM-DD"),
            duration: 1,
            status: 0,
            requesterComments: 'n.v.',
            managerComments: 'n.v.',
        }));
    }

    useEffect(() => {
        fetchLeaveRequestDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [args.leaveRequestId]);

    return (
        <Dialog open={args.open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseEditor();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>luggage</Icon>&nbsp;Einzelheiten</div>
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                {
                    pageState.leaveType !== '' ?
                        <form id="formLeaveRequest" method="post" onSubmit={handleLeaveRequestFormSubmit}>
                            <PPBox display="flex" justifyContent="space-between" mb={2}>
                                <PPTypography variant="button" fontWeight="bold" textTransform="capitalize">
                                    {getLeaveRequestTypeAsText(pageState.leaveType)} {getLeaveRequestStatusAsBadge(pageState.status)}
                                </PPTypography>
                            </PPBox>
                            <PPBox mb={2}>
                                <PPTypography variant="caption" fontWeight="medium">
                                    <Icon>person</Icon> Angefordert von:&nbsp;&nbsp;&nbsp;
                                    <PPTypography variant="caption" color="text" fontWeight="medium">
                                        {pageState.requestedByUser}
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                            <PPBox mb={2}>
                                <PPTypography variant="caption" fontWeight="medium">
                                    <Icon>numbers</Icon> Dauer (in Tagen):&nbsp;&nbsp;&nbsp;
                                    <PPTypography variant="caption" color="text" fontWeight="medium">
                                        {pageState.duration}
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                            <PPBox mb={2}>
                                <PPTypography variant="caption" fontWeight="medium">
                                    <Icon>schedule</Icon> Startdatum:&nbsp;&nbsp;&nbsp;
                                    <PPTypography variant="caption" color="text" fontWeight="medium">
                                        {pageState.startDate}
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                            <PPBox mb={2}>
                                <PPTypography variant="caption" fontWeight="medium">
                                    <Icon>schedule</Icon> Enddatum:&nbsp;&nbsp;&nbsp;
                                    <PPTypography variant="caption" color="text" fontWeight="medium">
                                        {pageState.endDate}
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                            <PPBox mb={2}>
                                <PPTypography variant="caption" fontWeight="medium">
                                    <Icon>schedule</Icon> Datum der Einreichung:&nbsp;&nbsp;&nbsp;
                                    <PPTypography variant="caption" color="text" fontWeight="medium">
                                        {pageState.createdDate}
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                            <PPBox mb={2}>
                                <PPTypography variant="caption" fontWeight="medium">
                                    <Icon>comment</Icon> Kommentare des Anforderers:&nbsp;&nbsp;&nbsp;
                                    <PPTypography variant="caption" color="text" fontWeight="medium">
                                        {pageState.requesterComments}
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                            <PPBox mb={2}>
                                <PPTypography variant="caption" fontWeight="medium">
                                    <Icon>comment</Icon> Kommentare des Managers:&nbsp;&nbsp;&nbsp;
                                    <PPTypography variant="caption" color="text" fontWeight="medium">
                                        {
                                            args.newStatus === 'View' || args.newStatus === 'Cancel' ? <>{pageState.managerComments}</> : <PPInput type="textarea" value={pageState.managerComments} onChange={handleManagerCommentsChange} variant="standard" fullWidth />
                                        }
                                    </PPTypography>
                                </PPTypography>
                            </PPBox>
                        </form>
                        : null
                }
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
                            <Icon>cancel</Icon>&nbsp;Schließen
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>

                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        {
                            args.newStatus === 'Approve' ?
                                <PPButton variant="gradient" color="success" type="submit" form="formLeaveRequest" fullWidth>
                                    <Icon>thumb_up_off_alt</Icon>&nbsp;{t('approve')}
                                </PPButton> : null
                        }
                        {
                            args.newStatus === 'Reject' ?
                                <PPButton variant="gradient" color="error" type="submit" form="formLeaveRequest" fullWidth>
                                    <Icon>thumb_down_off_alt</Icon>&nbsp;{t('reject')}
                                </PPButton> : null
                        }
                        {
                            args.newStatus === 'Cancel' ?
                                <PPButton variant="gradient" color="error" type="submit" form="formLeaveRequest" fullWidth>
                                    <Icon>not_interested</Icon>&nbsp;{t('cancel')}
                                </PPButton> : null
                        }
                    </Grid>
                </Grid>
            </DialogActions>
            <Spinner open={openSpinnerPanel} />
        </Dialog>
    );
}

export default ApprovalEditor;