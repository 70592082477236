import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequestOptions, getCurrentUserId } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import moment from 'moment';
import AlertDialog from 'controls/Dialogs/AlertDialog';
import Spinner from "controls/Spinner";
import { useTranslation } from 'react-i18next';

const LeaveRequestEditor = (args) => {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [pageState, setPageState] = useState({
        id: args.leaveRequestId,
        leaveType: '1',
        startDate: moment(new Date()).format("yyyy-MM-DD"),
        endDate: moment(new Date()).format("yyyy-MM-DD"),
        duration: 1,
        requesterComments: '',
    });

    const handleLeaveRequestTypeChange = (e) => {
        document.getElementById('hfSelectedLeaveRequestType').value = e.target.value;
        setPageState(x => ({ ...x, leaveType: e.target.value ? e.target.value : 'Annual Leave' }));
    };

    const handleDurationChange = (e) => {
        setPageState(x => ({ ...x, duration: e.target.value ? e.target.value : '' }));
    };

    const handleCommentsChange = (e) => {
        setPageState(x => ({ ...x, requesterComments: e.target.value ? e.target.value : '' }));
    };

    const handleLeaveRequestFormSubmit = (e) => {
        e.preventDefault();
        if(pageState.startDate > pageState.endDate){
            setAlertDialogOpen(true);
            return;
        }
        setOpenSpinnerPanel(true);
        var body = JSON.stringify({
            "id": pageState.id ? pageState.id : null,
            "userId": getCurrentUserId(),
            "type": pageState.leaveType,
            "startDate": pageState.startDate,
            "endDate": pageState.endDate,
            "duration": pageState.duration,
            "requesterComments": pageState.requesterComments ? pageState.requesterComments : null,
        });
        var fetchURL = appEndPoints.postLeaveRequestEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('POST', body, 'json');
        if (pageState.id !== null && pageState.id !== '') {
            fetchURL = appEndPoints.putLeaveRequestEndpoint.replace("{hostname}", appEndPoints.hostname);
            requestOptions = getRequestOptions('PUT', body, 'json');
        }
        fetch(fetchURL, requestOptions)
            .then(resp => resp.json())
            .then(resp => {
                handleCloseEditor();
                setPageState(x => ({ ...x, id: '', leaveType: '1', startDate: moment(new Date()).format("yyyy-MM-DD"), endDate: moment(new Date()).format("yyyy-MM-DD"), duration: 1, requesterComments: '' }));
                args.handleRefreshListing();
                setOpenSpinnerPanel(false);
            })
            .catch(error => { console.log('error', error); })
    };

    function fetchLeaveRequestDetails() {
        if (args.leaveRequestId) {
            setOpenSpinnerPanel(true);
            var fetchURL = appEndPoints.leaveRequestsEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + args.leaveRequestId;
            fetch(fetchURL, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, id: resp.id,
                        leaveType: resp.type,
                        startDate: resp.startDate ? resp.startDate : null,
                        endDate: resp.endDate ? resp.endDate : null,
                        duration: resp.duration,
                        requesterComments: resp.requesterComments ? resp.requesterComments : '',
                    }));
                    setOpenSpinnerPanel(false);
                })
                .catch(error => { console.log('error', error); })
        }
        else{
            resetForm();
        }
    }

    const handleCloseEditor = (e) => {
        resetForm();
        args.handleCloseDialog();
    };

    function resetForm(){
        setPageState(x => ({
            ...x, id: '', 
            leaveType: '1',
            startDate: moment(new Date()).format("yyyy-MM-DD"),
            endDate: moment(new Date()).format("yyyy-MM-DD"),
            duration: 1,
            requesterComments: '',
        }));
    }
    useEffect(() => {
        fetchLeaveRequestDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [args.leaveRequestId]);

    return (
        <Dialog open={args.open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseEditor();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                {
                    args.leaveRequestId !== null && args.leaveRequestId !== '' ?
                        <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>luggage</Icon>&nbsp;Einzelheiten</div>
                        : <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>luggage</Icon>&nbsp;Neue Abwesenheitszeit</div>
                }
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <form id="formLeaveRequest" method="post" onSubmit={handleLeaveRequestFormSubmit}>
                    <PPBox mb={2}>
                        <Select value={pageState.leaveType} onChange={handleLeaveRequestTypeChange} className='selectList' sx={{ minWidth: 250, maxHeight: 35 }} fullWidth>
                            <MenuItem key='1' value='1'>Jahresurlaub</MenuItem>;
                            <MenuItem key='2' value='2'>Krankheit</MenuItem>;
                            <MenuItem key='3' value='3'>Elternzeit</MenuItem>;
                            <MenuItem key='4' value='4'>Unbezahlter Urlaub</MenuItem>;
                            <MenuItem key='5' value='5'>Trauerfall</MenuItem>;
                            <MenuItem key='6' value='6'>Krankheit Kind</MenuItem>;
                            <MenuItem key='7' value='7'>Bezahlte Abwesenheit</MenuItem>;
                        </Select>
                        <input type="hidden" id="hfSelectedLeaveRequestType" />
                    </PPBox>
                    <PPBox mb={2}>
                        <PPInput type="number" label={t("durationInDays")} value={pageState.duration} onChange={handleDurationChange} variant="standard" required fullWidth />
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput
                                    label="Startdatum" InputLabelProps={{ shrink: true }} type="date"
                                    value={moment(pageState.startDate).format("yyyy-MM-DD") || ''}
                                    onChange={(e) => {
                                        setPageState(x => ({ ...x, startDate: e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD") }));
                                        //const newStartDate = e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD");
                                        // const newEndDate = pageState.endDate; // Get current end date
                                        // if (newStartDate < newEndDate) {
                                        //     setPageState(x => ({ ...x, startDate: newStartDate }));
                                        // } else {
                                        //     setAlertDialogOpen(true);
                                        // }
                                    }}
                                    variant="standard" required fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput
                                    label="Enddatum" InputLabelProps={{ shrink: true }} type="date"
                                    value={moment(pageState.endDate).format("yyyy-MM-DD") || ''}
                                    onChange={(e) => {
                                        setPageState(x => ({ ...x, endDate: e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD") }));
                                        // const newEndDate = e.target.value ? e.target.value : moment(e.target.value).format("yyyy-MM-DD");
                                        // const newStartDate = pageState.startDate; // Get current start date
                                        // if (newEndDate > newStartDate) {
                                        //     setPageState(x => ({ ...x, endDate: newEndDate }));
                                        // } else {
                                        //     setAlertDialogOpen(true);
                                        // }
                                    }}
                                    variant="standard" required fullWidth
                                />
                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <PPInput type="textarea" placeholder="Kommentare" label="Kommentare" value={pageState.requesterComments} onChange={handleCommentsChange} variant="standard" fullWidth />
                    </PPBox>
                </form>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
                            <Icon>cancel</Icon>&nbsp;Schließen
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>

                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="primary" type="submit" form="formLeaveRequest" fullWidth>
                            <Icon>save</Icon>&nbsp;Speichern
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
            <Spinner open={openSpinnerPanel} />
            <AlertDialog
                open={alertDialogOpen}
                onClose={() => setAlertDialogOpen(false)}
                message={t("invalidStartEndDates")}
            />
        </Dialog>
    );
}

export default LeaveRequestEditor;