import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import { appEndPoints } from "appSettings";
import { getCurrentUser, getRequestOptions, weekdaysJson } from "controls/Helpers";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Spinner from "controls/Spinner";
import { useTranslation } from 'react-i18next';

function Preferences() {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        id: '',
        hiddenDaysInCalendar: getCurrentUser().hiddenDaysInCalendar,
        viewMode: "view",
        isLoading: false,
    });

    const handleHiddenDaysSwitchChange = (dayId) => {
        setPageState((prevPageState) => {
            const isDayHidden = prevPageState.hiddenDaysInCalendar.includes(dayId);

            return {
                ...prevPageState,
                hiddenDaysInCalendar: isDayHidden
                    ? prevPageState.hiddenDaysInCalendar.filter((day) => day !== dayId)
                    : [...prevPageState.hiddenDaysInCalendar, dayId],
            };
        });
    };

    const handleEditClick = () => {
        setPageState(old => ({ ...old, viewMode: "edit" }))
    };

    const handleCancelEdit = () => {
        setPageState(old => ({ ...old, viewMode: "view" }))
    };

    const handlePreferencesUpdate = (e) => {
        e.preventDefault();
        setOpenSpinnerPanel(true);
        const myForm = document.getElementById("updatePreferences");
        var userToUpdate = getCurrentUser();
        const formData = new FormData(myForm);
        formData.append("userId", userToUpdate.id ? userToUpdate.id : null);
        formData.append("hiddenDaysInCalendar", JSON.stringify(pageState.hiddenDaysInCalendar));
        var fetchURL = appEndPoints.putMyPreferencesEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('PUT', formData);
        fetch(fetchURL, requestOptions)
            .then(resp => resp.json())
            .then(resp => {
                let updatedUser = getCurrentUser();
                updatedUser.hiddenDaysInCalendar = pageState.hiddenDaysInCalendar;
                window.localStorage.setItem('currentUser', JSON.stringify(updatedUser));
                setPageState(old => ({ ...old, viewMode: "view", isLoading: false }));
                setOpenSpinnerPanel(false);
            })
            .catch(error => { console.log('error', error); })
    };

    const renderWeekdays = Object.keys(weekdaysJson).map((k) => (
        <PPBox key={weekdaysJson[k].index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
            lineHeight={1}>
            <PPTypography variant="button" fontWeight="regular" textTransform="capitalize">
                {weekdaysJson[k].longName}
            </PPTypography>
            <Switch disabled={pageState.viewMode !== "edit"}
                checked={pageState.hiddenDaysInCalendar.includes(weekdaysJson[k].index)}
                onChange={() => handleHiddenDaysSwitchChange(weekdaysJson[k].index)}
            />
        </PPBox>
    ));

    return (
        <PPBox p={2}>
            <PPBox display="flex" justifyContent="space-between" alignItems="center">
                <PPTypography variant="h5" fontWeight="medium" style={{ textTransform: "uppercase" }}>
                    {t("myPreferences")}
                </PPTypography>
                <PPButton color="primary" onClick={handleEditClick} disabled={pageState.viewMode === "edit" ? true : false}>
                    <Icon>edit</Icon>&nbsp;
                    {t("edit")}
                </PPButton>
            </PPBox>
            <PPBox>
                <PPBox opacity={0.3}>
                    <Divider />
                </PPBox>
                <PPBox>
                    <form id="updatePreferences" method="POST" encType="multipart/form-data" name="preferencesForm" onSubmit={handlePreferencesUpdate}>
                        <PPTypography variant="button" fontWeight="regular" textTransform="capitalize">
                            {t("hideDaysFromCalendar")}
                        </PPTypography>
                        <PPBox mb={4}>
                            {renderWeekdays}
                        </PPBox>
                        <Grid container spacing={2}>
                            {
                                pageState.viewMode === "edit" ?
                                    <PPBox style={{ width: "100%" }}>
                                        <div style={{ float: "right", paddingLeft: "10px" }}>
                                            <PPButton color="primary" type="submit" form="updatePreferences" fullWidth>
                                                <Icon>save</Icon>&nbsp;{t("save")}
                                            </PPButton>
                                        </div>
                                        <div style={{ float: "right", paddingRight: "10px" }}>
                                            <PPButton variant="gradient" color="light" onClick={handleCancelEdit} fullWidth>
                                                <Icon>cancel</Icon>&nbsp;{t("cancel")}
                                            </PPButton>
                                        </div>
                                    </PPBox>
                                    : null
                            }
                        </Grid>
                    </form>
                </PPBox>
            </PPBox>
            <Spinner open={openSpinnerPanel} />
        </PPBox>
    );
}

export default Preferences;
