export const getRepeatIntervalArray = () => {
    var elements = [];
    elements.push({
        value: 1,
        label: "Jede Woche",
    });
    for (var i = 2; i <= 31; i++) {
        elements.push({
            value: i,
            label: `${i} Wochen`,//`${i}` + " Wochen",
        });
    }
    return elements;
};

export const getStatusArray = () => {
    var elements = [];
    elements.push({
        value: -1,
        label: "Nichts Ausgewählt",
    });
    elements.push({
        value: 1,
        label: "Completed",
    });
    elements.push({
        value: 2,
        label: "Canceled before 24 hours",
    });
    elements.push({
        value: 3,
        label: "Canceled on the same day",
    });
    return elements;
};