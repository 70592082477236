import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your language resources
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';

// Get the language from the stored member data
let currentUser = JSON.parse(window.localStorage.getItem('currentUser'));
const browserLanguage = navigator.language.split('-')[0];
const defaultLanguage = currentUser && currentUser.language ? currentUser.language
  : ['en', 'de'].includes(browserLanguage)
    ? browserLanguage
    : 'de'; // Set default language to 'de' if not available
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
    },
    lng: defaultLanguage,
    fallbackLng: 'de', // Default language fallback
    debug: false, // Enable debug mode for development
    interpolation: {
      escapeValue: false, // React already escapes values by default
    },
  });

export default i18n;