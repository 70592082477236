import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPInput from "components/PPInput";
import PPButton from "components/PPButton";
import BasicLayout from "pages/account/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpg";
import logoImage from "assets/images/logos/praxipro-logo.png";
import Icon from "@mui/material/Icon";
import { appEndPoints } from "appSettings";
import { Divider } from "@mui/material";
import { getRequestOptions, onSuccessfulLogin, setPageTitle } from "controls/Helpers";
import Spinner from "controls/Spinner";
import { useTranslation } from 'react-i18next';

function ResetPassword() {
    const { t } = useTranslation();
    setPageTitle(t('resetPassword'));
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [invalidLink, setInvalidLink] = useState(false);
    const [confirmingLink, setConfirmingLink] = useState(true);
    const [invalidPasswords, setInvalidPasswords] = useState(false);
    const [mode, setMode] = useState('registration');
    // Access the history object
    const navigate = useNavigate();
    const confirmUserRegistration = () => {
        setOpenSpinnerPanel(true);
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token') || '';
        const currentMdode = searchParams.get('mode') || '';
        setMode(currentMdode);
        if (currentMdode === 'registration' && token !== '') {
            var body = JSON.stringify({
                'token': token
            });
            var serviceUrl = appEndPoints.patchConfirmRegistrationEndpoint.replace('{hostname}', appEndPoints.hostname);
            fetch(serviceUrl, getRequestOptions('PATCH', body, 'json'))
                .then(resp => resp.json())
                .then(resp => {
                    setOpenSpinnerPanel(false);
                    setConfirmingLink(false);
                    setInvalidLink(!resp);
                })
                .catch(error => { setInvalidLink(true); setOpenSpinnerPanel(false); console.log('error', error); })
        }
        else if (currentMdode === 'forgotpassword' && token !== '') {
            setOpenSpinnerPanel(false);
            setConfirmingLink(false);
        }
        else {
            navigate('/');
        }
    };

    const handleResetPasswordFormSubmit = (e) => {
        e.preventDefault();
        setOpenSpinnerPanel(true);
        setInvalidPasswords(false);
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get('token') || '';
        // Access the form element...
        const resetPasswordForm = document.getElementById("formResetPassword");
        // Bind the FormData object and the form element
        const formData = new FormData(resetPasswordForm);
        var object = {};
        formData.append("id", token);
        formData.append("token", token);
        formData.forEach((value, key) => object[key] = value);
        if (object.newPassword !== object.confirmPassword) {
            setInvalidPasswords(true);
            setOpenSpinnerPanel(false);
            return;
        }
        var requestOptions = {
            method: 'PATCH',
            body: JSON.stringify(object),
            headers: {
                'Content-Type': 'application/json'
            }
        };
        var serviceUrl = appEndPoints.patchResetPasswordEndpoint.replace("{hostname}", appEndPoints.hostname);
        var status = 200;
        fetch(serviceUrl, requestOptions)
            .then(resp => {
                status = resp.status;
                return resp.json();
            })
            .then(resp => {
                if (status === 200) {
                    onSuccessfulLogin(resp.token);
                }
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    useEffect(() => {
        confirmUserRegistration();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <PPBox mx={2} p={2} textAlign="center">
                    <a href="https://www.praxipro.de" target="_blank" rel="noreferrer">
                        <PPBox component="img" src={logoImage} alt="" width="60%" mt={1} />
                    </a>
                    <Divider />
                    {
                        confirmingLink ? null : <PPTypography variant="h5" fontWeight="medium" color="dark" mt={1}>
                            Neues Passwort bereitstellen
                        </PPTypography>
                    }
                </PPBox>
                <PPBox pb={3} px={3}>
                    {
                        confirmingLink ? null :
                            invalidLink ?
                                <PPTypography variant="button" color="text">
                                    <p>Die Bestätigungs-URL ist ungültig. Bitte wenden Sie sich an Ihren Manager, um Unterstützung bei der Bereitstellung eines neuen Einladungslinks zu erhalten.</p>
                                </PPTypography> :
                                <PPTypography variant="button" color="text">
                                    {
                                        mode === "registration" ? <p>Ihr Konto ist einsatzbereit. Bitte geben Sie ein neues Passwort ein, um sich anzumelden.</p> : null
                                    }
                                    {
                                        mode === "forgotpassword" ? <p>Bitte geben Sie ein neues Passwort ein, um sich anzumelden.</p> : null
                                    }
                                    {
                                        invalidPasswords ? <PPBox pt={2}>
                                            <PPTypography variant="button" color="error" fontWeight="medium" textGradient>
                                                {t("invalidConfirmPassword")}
                                            </PPTypography></PPBox> : null
                                    }
                                    <PPBox pt={2} component="form" role="form" method="POST" id="formResetPassword" name="formResetPassword" onSubmit={handleResetPasswordFormSubmit}>
                                        <PPBox mb={2}>
                                            <PPInput type="password" label="Neu Passwort" name="newPassword" fullWidth required />
                                        </PPBox>
                                        <PPBox mb={2}>
                                            <PPInput type="password" label="Passwort bestätigen" name="confirmPassword" fullWidth required />
                                        </PPBox>
                                        <PPBox mt={4} mb={1}>
                                            <PPButton variant="gradient" color="primary" type="submit" form="formResetPassword" fullWidth>
                                                <Icon>login</Icon>&nbsp;Anmelden
                                            </PPButton>
                                        </PPBox>
                                    </PPBox>
                                </PPTypography>
                    }
                    <Spinner open={openSpinnerPanel} text="Wir bestätigen Ihr Benutzerkonto." />
                </PPBox>
            </Card>
        </BasicLayout >
    );
}

export default ResetPassword;
