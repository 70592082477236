import React, { useState } from 'react'
import Icon from "@mui/material/Icon";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Link from "@mui/material/Link";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';

function DocumentationFilesList({ id, fileNames, friendlyFileNames, patientId, viewMode }) {
    const { t } = useTranslation();
    const [pageState, setPageState] = useState({
        fileNames: fileNames ? fileNames.split(',').filter(n => n) : [],
        friendlyFileNames: friendlyFileNames ? friendlyFileNames.split(',').filter(n => n) : [],
        selectedFileName: ''
    });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleViewFileClick = (e, fileName) => {
        e.preventDefault();
        //var fileName = e.target.getAttribute('data-filename') ? e.target.getAttribute('data-filename') : "";
        if (fileName !== "" && fileName !== undefined) {
            var serviceUrl = appEndPoints.getFileBinary.replace("{hostname}", appEndPoints.hostname).replace("{id}", patientId).replace("{fileName}", fileName);
            fetch(serviceUrl, getRequestOptions())
                .then(response => response.blob())
                .then(blob => {
                    const fileUrl = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = fileUrl;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }).catch(error => { console.log('error', error); });
        }
    };

    const handleDeleteFileClick = (e, fileName) => {
        e.preventDefault();
        setPageState(old => ({ ...old, selectedFileName: fileName }));
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        var body = JSON.stringify({
            "id": id,
            "fileName": pageState.selectedFileName,
            "patientId": patientId
        });
        var serviceUrl = appEndPoints.deleteDocumentationFileEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                const index = pageState.fileNames.indexOf(pageState.selectedFileName);
                    if (index !== -1) {
                        var fileNamesArray = pageState.fileNames;
                        fileNamesArray.splice(index, 1);
                        var friendlyFileNamesArray = pageState.friendlyFileNames;
                        friendlyFileNamesArray.splice(index, 1);
                        setPageState(x => ({ ...x, fileNames: fileNamesArray, friendlyFileNames: friendlyFileNamesArray }));
                    }
            })
            .catch(error => { console.log('error', error); });
        // Close the confirm dialog after the action is performed
        setConfirmDialogOpen(false);
    };

    return (
        <div>
            {pageState.friendlyFileNames !== null && pageState.friendlyFileNames.length > 0 ?
                pageState.friendlyFileNames.map((item, index) => (
                    <div key={index} style={{ paddingBottom: "10px" }}>
                        <PPTypography variant="button" color="text">
                            <PPTypography display="inline" variant="body2" verticalAlign="middle">
                                <Icon sx={{ color: ({ palette: { success } }) => success.main }}>description</Icon>
                            </PPTypography>
                            &nbsp;
                            <PPTypography variant="button" color="dark">
                                <Link href="#" target="_blank" onClick={(e) => handleViewFileClick(e, pageState.fileNames[index])}>
                                    {item}
                                </Link>
                            </PPTypography>
                            {
                                viewMode !== "View" ? 
                                    <PPButton variant="text" color="error" onClick={(e) => handleDeleteFileClick(e, pageState.fileNames[index])}>
                                        <Icon>delete</Icon>&nbsp;Löschen
                                    </PPButton> : null
                            }
                        </PPTypography>
                    </div>
                )) : null
            }
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                message={t("confirmDeleteFileMsg")}
            />
        </div>
    );
}

export default DocumentationFilesList;
