import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import { useTranslation } from 'react-i18next';

const AlertDialog = ({ open, onClose, message }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("warning")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <PPButton variant="gradient" color="light" onClick={onClose} size="small">
          <Icon>cancel</Icon>&nbsp;{t("close")}
        </PPButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
