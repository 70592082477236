import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({ open, onClose, onConfirm, message }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("confirmAction")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <PPButton variant="gradient" color="light" onClick={onClose} size="small">
          <Icon>cancel</Icon>&nbsp;{t("close")}
        </PPButton>
        <PPButton variant="gradient" color="primary" onClick={() => { onConfirm(); onClose(); }} size="small">
          <Icon>check</Icon>&nbsp;{t("confirm")}
        </PPButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
