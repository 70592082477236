import React, { useState, useEffect } from 'react';
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import { appEndPoints } from "appSettings";
import moment from 'moment';
import { getRequestOptions } from "controls/Helpers";
import DataTable from "controls/Tables/DataTable";

function PublicHolidays() {

    const [pageState, setPageState] = useState({
        data: []
    });
    const columns = [
        { Header: "Feiertag", accessor: "holiday", align: "Left" },
        { Header: "Datum", accessor: "date", align: "Left" },
        { Header: "Bundesland", accessor: "state", align: "Left" },
    ];

    let rows =
        pageState.data.map((e, index) => ({
            holiday: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {e.holiday}
                </PPTypography>
            ),
            date: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {e.date ? moment(e.date, "YYYY-MM-DD").format("DD.MM.YYYY") : "-"}
                </PPTypography>
            ),
            state: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {e.state}
                </PPTypography>
            )
        }));

    const fetchGridData = async () => {
        try {
            const fetchURL = appEndPoints.publicHolidaysEndpoint.replace("{hostname}", appEndPoints.hostname);
            const response = await fetch(fetchURL, getRequestOptions());
            const data = await response.json();
            setPageState((old) => ({ ...old, data: data }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchGridData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PPBox pt={0} pb={3}>
            <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
            />
        </PPBox>
    );
}

export default PublicHolidays;
