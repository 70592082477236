import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "controls/Sidenav";
import theme from "assets/theme";
import routes from "routes";
import { usePraxiProUIController, setMiniSidenav } from "context";
import brand from "assets/images/logo-ct.png";
import "assets/theme/app.css"
import DashboardNavbar from "controls/Navbars/DashboardNavbar";
import { isAuthenticated } from "controls/Helpers";
import SignIn from "pages/account/sign-in";
import ReactGA from 'react-ga4';
import i18n from './i18n';

i18n.init();

export default function App() {
  const [controller, dispatch] = usePraxiProUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const TRACKING_ID = "G-J5JFQ9F58X"; // PraxiPro_TRACKING_ID

  ReactGA.initialize(TRACKING_ID);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      else if (route.access === "public" || (route.access === "private" && isAuthenticated())) {
        return <Route exact path={route.route} element={route.component} key={route.key} title={route.name} />;
      }
      else if (route.access === "private" && !isAuthenticated()) {
        return <Route exact path={route.route} element={<SignIn />} key={route.key} title={route.name} />;
      }
      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && isAuthenticated() && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="PraxiPro"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <DashboardNavbar />
        </>
      )}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}
