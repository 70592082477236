import Icon from "@mui/material/Icon";
import PPButton from "components/PPButton";
import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy({ open, handleCloseDialog }) {
    const { t } = useTranslation();

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseDialog();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>lock_open</Icon>&nbsp;{t("privacyPolicy")}</div>
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <div class="terms-section">
                    <p>{t("privacyPolicyTxt1")}</p><br />
                    <p><h4>{t("privacyPolicyTxt2")}</h4>
                    <ul class="list-group">
                            <li>{t("privacyPolicyTxt3")}</li>
                            <li>{t("privacyPolicyTxt4")}</li>
                        </ul>
                        </p><br />
                    <p><h4>{t("privacyPolicyTxt5")}:</h4>
                        <ul class="list-group">
                            <li>{t("privacyPolicyTxt6")}</li>
                            <li>{t("privacyPolicyTxt7")}</li>
                        </ul></p><br />
                    <p><h4>{t("privacyPolicyTxt8")}</h4>
                        <ul class="list-group">
                            <li>{t("privacyPolicyTxt9")}</li>
                            <li>{t("privacyPolicyTxt10")}</li>
                        </ul></p><br />
                    <p><h4>{t("privacyPolicyTxt11")}</h4>
                    <ul class="list-group">
                            <li>{t("privacyPolicyTxt12")}</li>
                            <li>{t("privacyPolicyTxt13")}</li>
                        </ul>
                    </p><br />
                    <p><h4>{t("privacyPolicyTxt14")}</h4>
                        <ul class="list-group">
                            <li>{t("privacyPolicyTxt15")}</li>
                            <li>{t("privacyPolicyTxt16")}</li>
                        </ul>
                    </p><br />
                    <p><h4>{t("privacyPolicyTxt17")}</h4>
                        {t("privacyPolicyTxt18")}
                    </p><br />
                    <p><h4>{t("privacyPolicyTxt19")}</h4>
                        {t("privacyPolicyTxt20")}
                    </p><br />
                    <p>{t("privacyPolicyTxt21")}</p><br />
                    <p>{t("privacyPolicyTxt22")}</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseDialog} fullWidth>
                            <Icon>cancel</Icon>&nbsp;{t("close")}
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default PrivacyPolicy;