import React, { useState } from 'react';
import Icon from "@mui/material/Icon";
import PPButton from "components/PPButton";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Spinner from "controls/Spinner";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import AlertDialog from 'controls/Dialogs/AlertDialog';

function UserFeedback({ open, handleCloseDialog }) {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        id: null,
        content: '',
        status: 'Submitted',
    });
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);

    const handleContentChange = (html) => {
        setPageState(x => ({ ...x, content: html || '' }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (pageState.content.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            return
        }
        setOpenSpinnerPanel(true);
        var body = JSON.stringify({
            "id": pageState.id ? pageState.id : null,
            "description": pageState.content ? pageState.content : '',
            "status": pageState.status,
        });
        var fetchURL = appEndPoints.postFeedbackEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('POST', body, 'json');
        if (pageState.id !== null && pageState.id !== '') {
            fetchURL = appEndPoints.putFeedbackEndpoint.replace("{hostname}", appEndPoints.hostname);
            requestOptions = getRequestOptions('PUT', body, 'json');
        }
        fetch(fetchURL, requestOptions)
            .then(resp => resp.text())
            .then(resp => {
                setOpenSpinnerPanel(false);
                setPageState(x => ({ ...x, content: '' }));
                handleCloseDialog();
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseDialog();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", alignItems: "center" }}>{t("feedbackFormTitle")}</div>
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <div className="feebackFormIntro">
                    <p>{t("feedbackFormIntroText1")}</p>
                    <ul>
                        <li><div dangerouslySetInnerHTML={{ __html: t("feedbackFormIntroText2") }} /></li>
                        <li><div dangerouslySetInnerHTML={{ __html: t("feedbackFormIntroText3") }} /></li>
                        <li><div dangerouslySetInnerHTML={{ __html: t("feedbackFormIntroText4") }} /></li>
                    </ul>
                    <p>{t("feedbackFormIntroText5")}</p>
                </div>
                <form id="formReportProblem" method="post" onSubmit={handleFormSubmit}>
                    <ReactQuill theme="snow" value={pageState.content} onChange={handleContentChange} required />
                </form>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseDialog} fullWidth>
                            <Icon>cancel</Icon>&nbsp;{t("close")}
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="primary" type="submit" form="formReportProblem" fullWidth>
                            <Icon>publish</Icon>&nbsp;{t("submit")}
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
            <Spinner open={openSpinnerPanel} />
            <AlertDialog
                open={alertDialogOpen}
                onClose={() => setAlertDialogOpen(false)}
                message={t("unableToDeleteMsg")}
            />
        </Dialog>
    );
}

export default UserFeedback;