

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for PPInput
import PPInputRoot from "components/PPInput/PPInputRoot";

const PPInput = forwardRef(({ error, success, disabled, ...rest }, ref) => (
  <PPInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
));

// Setting default values for the props of PPInput
PPInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the PPInput
PPInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PPInput;
