import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import React, { useState, useEffect, useCallback } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import PPTypography from "components/PPTypography";
import Switch from "@mui/material/Switch";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import AlertDialog from 'controls/Dialogs/AlertDialog';
import Spinner from "controls/Spinner";
import DataTable from "controls/Tables/DataTable";
import { TextField, Autocomplete } from "@mui/material";
import { useTranslation } from 'react-i18next';

const WorkingHoursEditor = ({ open, userId, handleCloseDialog }) => {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        id: null,
        data: [],
        current: false,
        workingHours: '',
        viewMode: 'view',
        selectedStartMonth: null,
        selectedStartYear: null,
        selectedEndMonth: null,
        selectedEndYear: null,
    });
    const [confirmMessage, setConfirmMessage] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);

    const columns = [
        { Header: "Wochenarbeitszeit", accessor: "workingHours", width: "15%", align: "left" },
        { Header: "Startdatum", accessor: "startDate", align: "center" },
        { Header: "Enddatum", accessor: "endDate", align: "center" },
        { Header: "Aktion", accessor: "action", align: "center" },
    ];

    const rows = Array.isArray(pageState.data) ? pageState.data.map((e, index) => ({
        workingHours: (
            <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                {e.workingHours}
            </PPTypography>
        ),
        startDate: (
            <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                {e.startMonth}/{e.startYear}
            </PPTypography>
        ),
        endDate: (
            <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                {e.endMonth > 0 ? `${e.endMonth}/${e.endYear}` : ''}
            </PPTypography>
        ),
        action: (
            <>
                <PPButton variant="outlined" color="primary" size="small" onClick={() => handleEditClick(e.id)}>
                    <Icon>edit</Icon>
                </PPButton>
                &nbsp;
                {
                    pageState.data.length > 1 && (
                        <PPButton variant="outlined" color="primary" size="small" onClick={() => handleDeleteClick(e)}>
                            <Icon>delete</Icon>
                        </PPButton>
                    )
                }
            </>
        ),
    })) : [];

    const handleWorkingHoursChange = (e) => {
        setPageState(x => ({ ...x, workingHours: e.target.value ? e.target.value : '' }));
    };

    const generateMonthNames = () => {
        const months = [];
        const date = new Date();
        for (let i = 0; i < 12; i++) {
            date.setMonth(i);
            months.push({ label: date.toLocaleDateString(undefined, { month: 'long' }), value: i + 1 });
        }
        return months;
    };

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = currentYear + 10; year >= 2000; year--) {
            years.push({ label: year.toString(), value: year });
        }
        return years;
    };

    const handleCloseEditor = (e) => {
        handleCloseDialog();
        resetForm();
    };

    const handleStartMonthChange = (e, newValue) => {
        setPageState(old => ({ ...old, selectedStartMonth: newValue.value }));
    };

    const handleStartYearChange = (e, newValue) => {
        setPageState(old => ({ ...old, selectedStartYear: newValue.value }));
    };

    const handleEndMonthChange = (e, newValue) => {
        setPageState(old => ({ ...old, selectedEndMonth: newValue.value }));
    };

    const handleEndYearChange = (e, newValue) => {
        setPageState(old => ({ ...old, selectedEndYear: newValue.value }));
    };

    const handleAddItem = (arg) => {
        resetForm();
        setPageState(old => ({ ...old, id: null, viewMode: 'add' }));
    };

    const handleEditClick = (arg) => {
        setPageState(old => ({ ...old, id: arg, viewMode: 'edit' }));
        fetchUserWeeklyHoursById(arg);
    };

    const handleDeleteClick = (arg) => {
        if (!arg.current) {
            setPageState(old => ({ ...old, id: arg.id }));
            setConfirmMessage(t("confirmDeleteRecordMsg"));
            setConfirmDialogOpen(true);
        }
        else {
            setAlertMessage(t("alertDeleteCurrentWorkingHoursMsg"));
            setAlertDialogOpen(true);
        }
    };

    const handlBackClick = (arg) => {
        setPageState(old => ({ ...old, selectedItemId: null, viewMode: 'view' }));
        fetchUserWeeklyHours();
    };

    const handleIsCurrentChange = (e) => {
        const hasCurrentItem = pageState.data.some(item => item.current);
        const isCurrentItem = pageState.data.some(item => item.current && item.id === pageState.id);
        if (e.target.checked && hasCurrentItem && !isCurrentItem) {
            setAlertMessage(t("alertSetCurrentWorkingHoursMsg"));
            setAlertDialogOpen(true);
        }
        else {
            setPageState(x => ({ ...x, current: e.target.checked }));
        }
    };

    const handleConfirmAction = () => {
        setOpenSpinnerPanel(true);
        var body = JSON.stringify({
            "id": pageState.id,
        });
        var serviceUrl = appEndPoints.userWeeklyHoursEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                fetchUserWeeklyHours();
                setOpenSpinnerPanel(false);
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    const resetForm= useCallback(() => {
        // Set default selected month to current month
        const date = new Date();
        setPageState(x => ({
            ...x, id: null,
            current: false,
            workingHours: '',
            selectedStartMonth: 1,
            selectedStartYear: date.getFullYear(),
            selectedEndMonth: 1,
            selectedEndYear: (date.getFullYear() + 5),
            months: generateMonthNames(),
            years: generateYearOptions()
        }));
    }, []);

    const fetchUserWeeklyHours = async () => {
        setOpenSpinnerPanel(true);
        try {
            const fetchURL = appEndPoints.userWeeklyHoursEndpoint.replace("{hostname}", appEndPoints.hostname) + "/user/" + userId;
            const response = await fetch(fetchURL, getRequestOptions());
            const data = await response.json();
            setOpenSpinnerPanel(false);
            setPageState((old) => ({ ...old, data: data }));
        } catch (error) {
            setOpenSpinnerPanel(false);
            console.error('Error fetching data:', error);
        }
    }

    const fetchUserWeeklyHoursById = async (id) => {
        setOpenSpinnerPanel(true);
        try {
            const fetchURL = appEndPoints.userWeeklyHoursEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + id;
            const response = await fetch(fetchURL, getRequestOptions());
            const data = await response.json();
            setOpenSpinnerPanel(false);
            setPageState((old) => ({
                ...old, id: data.id, workingHours: data.workingHours,
                selectedStartMonth: data.startMonth,
                selectedStartYear: data.startYear,
                selectedEndMonth: data.endMonth,
                selectedEndYear: data.endYear,
                current: data.current
            }));
        } catch (error) {
            setOpenSpinnerPanel(false);
            console.error('Error fetching data:', error);
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setOpenSpinnerPanel(true);
        var body = JSON.stringify({
            "id": pageState.id ? pageState.id : null,
            "userId": userId,
            "workingHours": pageState.workingHours,
            "startMonth": pageState.selectedStartMonth,
            "startYear": pageState.selectedStartYear,
            "endMonth": pageState.current ? null : pageState.selectedEndMonth,
            "endYear": pageState.current ? null : pageState.selectedEndYear,
            "current": pageState.current
        });

        var fetchURL = appEndPoints.userWeeklyHoursEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = null;
        if (pageState.viewMode === 'add')
            requestOptions = getRequestOptions('POST', body, 'json');
        else if (pageState.viewMode === 'edit')
            requestOptions = getRequestOptions('PUT', body, 'json');
        fetch(fetchURL, requestOptions)
            .then(resp => resp.json())
            .then(resp => {
                fetchUserWeeklyHours();
                setPageState((old) => ({ ...old, viewMode: 'view' }));
                setOpenSpinnerPanel(false);
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    useEffect(() => {
        fetchUserWeeklyHours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, userId]);

    useEffect(() => {
        resetForm();
        setPageState(old => ({ ...old, viewMode: 'view' }));
    }, [resetForm]);

    return (
        <Dialog open={open} fullWidth maxWidth="md" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseEditor();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>work_history</Icon>&nbsp;Arbeitszeit</div>
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <PPBox mb={2}>
                    <PPTypography variant="caption" color="text">
                        {t('weeklyWorkingHoursMsg')}
                    </PPTypography>
                </PPBox>
                {
                    pageState.viewMode === 'view' ?
                        <DataTable
                            table={{ columns, rows }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        /> : null
                }
                {
                    pageState.viewMode === 'edit' || pageState.viewMode === 'add' ? <form id="formManageWorkingHours" method="post" onSubmit={handleFormSubmit}>
                        <PPBox borderRadius="lg" p={2} mb={3} coloredShadow="light">
                            <PPBox mb={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <TextField type="number" label="Wochenarbeitszeit" value={pageState.workingHours} onChange={handleWorkingHoursChange} variant="standard" required fullWidth />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <PPTypography variant="button" fontWeight="regular" textTransform="capitalize">
                                            Aktuell
                                        </PPTypography>
                                        <Switch color="primary" checked={pageState.current} onChange={handleIsCurrentChange} />
                                    </Grid>
                                </Grid>
                            </PPBox>
                        </PPBox>
                        <PPBox borderRadius="lg" p={2} mb={3} coloredShadow="light">
                            <PPBox mb={2}>
                                <PPTypography mb={2} variant="h6" fontWeight="medium">
                                    Startdatum
                                </PPTypography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Autocomplete
                                            disablePortal
                                            name="month"
                                            value={pageState.months.find(option => option.value === pageState.selectedStartMonth) || null}
                                            options={pageState.months}
                                            onChange={handleStartMonthChange}
                                            renderInput={(params) => <TextField {...params} name="month" label="Monat" fullWidth variant="standard" required />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Autocomplete
                                            disablePortal
                                            name="year"
                                            value={pageState.years.find(option => option.value === pageState.selectedStartYear) || null}
                                            options={pageState.years}
                                            onChange={handleStartYearChange}
                                            renderInput={(params) => <TextField {...params} name="year" label="Jahr" fullWidth variant="standard" required />}
                                        />
                                    </Grid>
                                </Grid>
                            </PPBox>
                        </PPBox>
                        {
                            !pageState.current ? <PPBox borderRadius="lg" p={2} mb={3} coloredShadow="light"><PPBox mb={2}>

                                <PPTypography mb={2} variant="h6" fontWeight="medium">
                                    Enddatum
                                </PPTypography>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Autocomplete
                                            disablePortal
                                            name="month"
                                            value={pageState.months.find(option => option.value === pageState.selectedEndMonth) || null}
                                            options={pageState.months}
                                            onChange={handleEndMonthChange}
                                            renderInput={(params) => <TextField {...params} name="month" label="Monat" fullWidth variant="standard" required />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <Autocomplete
                                            disablePortal
                                            name="year"
                                            value={pageState.years.find(option => option.value === pageState.selectedEndYear) || null}
                                            options={pageState.years}
                                            onChange={handleEndYearChange}
                                            renderInput={(params) => <TextField {...params} name="year" label="Jahr" fullWidth variant="standard" required />}
                                        />
                                    </Grid>
                                </Grid>
                            </PPBox>
                            </PPBox> : null
                        }

                    </form> : null
                }

            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
                            <Icon>cancel</Icon>&nbsp;Schließen
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        {
                            pageState.viewMode === 'edit' || pageState.viewMode === 'add' ? <PPButton variant="gradient" color="light" onClick={handlBackClick} fullWidth>
                                <Icon>arrow_back</Icon>&nbsp;Zurück
                            </PPButton> : null
                        }
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        {
                            pageState.viewMode === 'view' ? <PPButton variant="gradient" color="primary" onClick={handleAddItem} fullWidth>
                                <Icon>add_circle</Icon>&nbsp;
                                {t('add')}
                            </PPButton> : null
                        }
                        {
                            pageState.viewMode === 'edit' || pageState.viewMode === 'add' ? <PPButton variant="gradient" color="primary" type="submit" form="formManageWorkingHours" fullWidth>
                                <Icon>save</Icon>&nbsp;Speichern
                            </PPButton> : null
                        }
                    </Grid>
                </Grid>
            </DialogActions>
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmAction}
                message={confirmMessage}
            />
            <AlertDialog
                open={alertDialogOpen}
                onClose={() => setAlertDialogOpen(false)}
                message={alertMessage}
            />
            <Spinner open={openSpinnerPanel} />
        </Dialog>
    );
}

export default WorkingHoursEditor;