import Icon from "@mui/material/Icon";
import PPButton from "components/PPButton";
import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

function TermsOfService({ open, handleCloseDialog }) {
    const { t } = useTranslation();

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseDialog();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>business_center</Icon>&nbsp;{t("termsOfService")}</div>
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <div class="terms-section">
                    <p>{t("termsOfServiceTxt1")}</p><br />
                    <p><h4>{t("termsOfServiceTxt2")}</h4>
                        {t("termsOfServiceTxt3")}</p><br />
                    <p><h4>{t("termsOfServiceTxt4")}:</h4>
                        <ul class="list-group">
                            <li>{t("termsOfServiceTxt5")}</li>
                            <li>{t("termsOfServiceTxt6")}</li>
                        </ul></p><br />
                    <p><h4>{t("termsOfServiceTxt7")}</h4>
                        <ul class="list-group">
                            <li>{t("termsOfServiceTxt8")}</li>
                            <li>{t("termsOfServiceTxt9")}</li>
                        </ul></p><br />
                    <p><h4>{t("termsOfServiceTxt10")}</h4>
                        {t("termsOfServiceTxt11")}
                    </p><br />
                    <p><h4>{t("termsOfServiceTxt12")}</h4>
                        <ul class="list-group">
                            <li>{t("termsOfServiceTxt13")}</li>
                            <li>{t("termsOfServiceTxt14")}</li>
                        </ul>
                    </p><br />
                    <p><h4>{t("termsOfServiceTxt15")}</h4>
                        {t("termsOfServiceTxt16")}
                    </p><br />
                    <p><h4>{t("termsOfServiceTxt17")}</h4>
                        {t("termsOfServiceTxt18")}
                    </p><br />
                    <p><h4>{t("termsOfServiceTxt19")}</h4>
                        {t("termsOfServiceTxt20")}</p><br />
                    <p>{t("termsOfServiceTxt21")}</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseDialog} fullWidth>
                            <Icon>cancel</Icon>&nbsp;{t("close")}
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default TermsOfService;