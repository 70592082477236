import PPBox from "components/PPBox";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Spinner from "controls/Spinner";
import { useTranslation } from 'react-i18next';

const TemplateEditor = ({ templateId, handleRefreshListing }) => {
    const { t } = useTranslation();
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        id: templateId,
        name: '',
        content: '',
    });

    const handleNameChange = (e) => {
        setPageState(x => ({ ...x, name: e.target.value ? e.target.value : '' }));
    };

    const handleContentChange = (html) => {
        setPageState(x => ({ ...x, content: html || '' }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (pageState.content.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
            return
        }
        setOpenSpinnerPanel(true);
        var body = JSON.stringify({
            "id": pageState.id ? pageState.id : null,
            "name": pageState.name ? pageState.name : '',
            "content": pageState.content ? pageState.content : '',
        });
        var requestOptions = getRequestOptions('POST', body, 'json');
        if (pageState.id !== null && pageState.id !== '') {
            requestOptions = getRequestOptions('PUT', body, 'json');
        }
        fetch(appEndPoints.templateEndpoint.replace("{hostname}", appEndPoints.hostname), requestOptions)
            .then(resp => resp.text())
            .then(resp => {
                setOpenSpinnerPanel(false);
                handleRefreshListing();
            })
            .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
    };

    function fetchTemplateDetails() {
        if (templateId !== null && templateId !== '') {
            setOpenSpinnerPanel(true);
            fetch(appEndPoints.templateEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + templateId, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, id: resp.id,
                        name: resp.name,
                        content: resp.content,
                    }));
                    setOpenSpinnerPanel(false);
                })
                .catch(error => { setOpenSpinnerPanel(false); console.log('error', error); })
        }
        else {
            setPageState(x => ({ ...x, id: '', name: '', content: '' }));
        }
    }

    useEffect(() => {
        fetchTemplateDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId]);

    return (
        <div>
            <form id="formManageTemplate" method="post" onSubmit={handleFormSubmit} autoComplete="false">
                <PPBox mb={2}>
                    <PPInput type="text" label={t("templateName")} value={pageState.name} onChange={handleNameChange} variant="standard" autoComplete="false" fullWidth required />
                </PPBox>
                <PPBox mb={2}>
                    <ReactQuill theme="snow" value={pageState.content} onChange={handleContentChange} required />
                </PPBox>
            </form>
            <Spinner open={openSpinnerPanel} />
        </div>
    );
}

export default TemplateEditor;