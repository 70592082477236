import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Spinner from "controls/Spinner";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import DocumentationEditor from "./DocumentationEditor";
import DocumentationList from "./DocumentationList";

const DocumentationDialog = ({ open, patientIds, documentationDate, handleCloseDialog }) => {
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);
    const [pageState, setPageState] = useState({
        patientIds: [],
        documentationId: '',
        documentationDate: documentationDate,
        showEditor: false,
    });
    const handleOpenSpinnerPanel = (e) => {
        setOpenSpinnerPanel(e);
    };

    const handleEditClick = (e) => {
        setPageState(x => ({ ...x, showEditor: true, documentationId: e }));
    };

    const handleAddClick = (e) => {
        e.preventDefault();
        setPageState(x => ({ ...x, showEditor: true, documentationId: '' }));
    };

    const handlBackClick = (e) => {
        e.preventDefault();
        setPageState(x => ({ ...x, showEditor: false, documentationId: '' }));
    };

    const handleDocumentationSubmitCallback = (e) => {
        setPageState(x => ({ ...x, showEditor: patientIds?.length > 1 ? true : false }));
    };

    useEffect(() => {
    }, []);

    useEffect(() => {
        setPageState(x => ({ ...x, patientIds: patientIds, showEditor: patientIds?.length > 1 ? true : false }));
    }, [patientIds]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseDialog();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>attach_file</Icon>&nbsp;Dokumentation</div>
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <PPBox p={1}>
                    {
                        pageState.showEditor ? <DocumentationEditor id={pageState.documentationId} patientIds={patientIds}
                        documentationDate={documentationDate} handleSubmitCallback={handleDocumentationSubmitCallback} 
                        handleCloseDialog={handleCloseDialog} handleOpenSpinnerPanel={handleOpenSpinnerPanel} />
                            : <DocumentationList patientId={patientIds?.length > 0 ? patientIds[0] : []} handleEditClick={handleEditClick} />
                    }
                </PPBox>
            </DialogContent>
            <DialogActions>
                <Divider />
                {
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={4}>
                            <PPButton variant="gradient" color="light" onClick={handleCloseDialog} fullWidth>
                                <Icon>cancel</Icon>&nbsp;Schließen
                            </PPButton>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {
                                pageState.showEditor && patientIds.length === 1 ? <PPButton variant="gradient" color="light" onClick={handlBackClick} fullWidth>
                                    <Icon>arrow_back</Icon>&nbsp;Zurück
                                </PPButton> :
                                    null
                            }
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            {
                                pageState.showEditor ? <PPButton variant="gradient" color="primary" type="submit" form="formEventDocumentation" fullWidth>
                                    <Icon>save</Icon>&nbsp;Speichern
                                </PPButton> :
                                    <PPButton color="primary" onClick={handleAddClick} fullWidth>
                                        <Icon>add_circle</Icon>&nbsp;
                                        Hinzufügen
                                    </PPButton>
                            }
                        </Grid>
                    </Grid>
                }
            </DialogActions>
            <Spinner open={openSpinnerPanel} />
        </Dialog>
    );
}

export default DocumentationDialog;