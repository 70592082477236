import Icon from "@mui/material/Icon";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import PPInput from "components/PPInput";
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getRequestOptions } from "controls/Helpers";
import { appEndPoints } from "appSettings";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';
import Switch from "@mui/material/Switch";
import PPTypography from "components/PPTypography";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';

const EventCategoryEditor = ({ open, categoryId, handleRefreshListing, handleCloseDialog }) => {
    const { t } = useTranslation();
    const [pageState, setPageState] = useState({
        id: categoryId,
        displayName: '',
        duration: 0,
        rate: 0,
        enableDocumentation: true,
        workingTime: true,
    });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const handleNameChange = (e) => {
        setPageState(x => ({ ...x, name: e.target.value ? e.target.value : '' }));
    };

    const handleDurationChange = (e) => {
        setPageState(x => ({ ...x, duration: e.target.value ? e.target.value : '' }));
    };

    const handleRateChange = (e) => {
        setPageState(x => ({ ...x, rate: e.target.value ? e.target.value : '' }));
    };

    const handleEnableDocumentationSwitch = (e) => {
        setPageState(x => ({ ...x, enableDocumentation: !pageState.enableDocumentation }));
    };

    const handleWorkingTimeSwitch = (e) => {
        setPageState(x => ({ ...x, workingTime: !pageState.workingTime }));
    };

    const handleCloseEditor = (e) => {
        handleCloseDialog();
        setPageState(x => ({ ...x, id: '', name: '', duration: 0, rate: 0, enableDocumentation: true, workingTime: true }));
    };

    const handleConfirmDelete = () => {
        var body = JSON.stringify({
            "id": pageState.id,
        });
        var serviceUrl = appEndPoints.deleteEventCategoriesEndpoint.replace("{hostname}", appEndPoints.hostname);

        fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                handleRefreshListing();
                handleCloseEditor();
            })
            .catch(error => { console.log('error', error); })
    };

    const handleDeleteClick = (e) => {
        e.preventDefault();
        setConfirmDialogOpen(true);

    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        var body = JSON.stringify({
            "id": pageState.id ? pageState.id : null,
            "name": pageState.name ? pageState.name : '',
            "duration": pageState.duration ? pageState.duration : 0,
            "rate": pageState.workingTime ? (pageState.rate ? pageState.rate : 0) : 0,
            "enableDocumentation": pageState.workingTime ? (pageState.enableDocumentation ? pageState.enableDocumentation : false) : false,
            "workingTime": pageState.workingTime ? pageState.workingTime : false,
        });

        var fetchURL = appEndPoints.postEventCategoriesEndpoint.replace("{hostname}", appEndPoints.hostname);
        var requestOptions = getRequestOptions('POST', body, 'json');
        if (pageState.id !== null && pageState.id !== '') {
            fetchURL = appEndPoints.putEventCategoriesEndpoint.replace("{hostname}", appEndPoints.hostname);
            requestOptions = getRequestOptions('PUT', body, 'json');
        }
        fetch(fetchURL, requestOptions)
            .then(resp => resp.text())
            .then(resp => {
                handleRefreshListing();
                handleCloseEditor();
            })
            .catch(error => { console.log('error', error); })
    };

    function fetchCategoryDetails() {
        setPageState(x => ({ ...x, id: '', imageUrl: '' }));
        if (categoryId !== null && categoryId !== '' && open === true) {
            var fetchURL = appEndPoints.getEventCategoriesEndpoint.replace("{hostname}", appEndPoints.hostname) + "/" + categoryId;
            fetch(fetchURL, getRequestOptions())
                .then(resp => resp.json())
                .then(resp => {
                    setPageState(x => ({
                        ...x, id: resp.id,
                        name: resp.name,
                        duration: resp.duration,
                        rate: resp.rate,
                        enableDocumentation: resp.enableDocumentation,
                        workingTime: resp.workingTime,
                    }));
                })
                .catch(error => { console.log('error', error); })
        }
        else {
            setPageState(x => ({ ...x, id: '', name: '', duration: 0, rate: 0, enableDocumentation: true, workingTime: true }));
        }
    }

    useEffect(() => {
        fetchCategoryDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, categoryId]);

    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={(_, reason) => {
            if (reason !== "backdropClick") {
                handleCloseEditor();
            }
        }} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
                {
                    categoryId !== null && categoryId !== '' ?
                        <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>event</Icon>&nbsp;Einzelheiten</div>
                        : <div style={{ display: "flex", alignItems: "center" }}><Icon fontSize="medium" sx={{ mt: -0.25 }}>event</Icon>&nbsp;Neue Kategorie</div>
                }
            </DialogTitle>
            <DialogContent dividers style={{ minHeight: "72vh" }}>
                <form id="formManageCategory" method="post" onSubmit={handleFormSubmit}>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="text" placeholder="Name" label="Name" value={pageState.name} onChange={handleNameChange} variant="standard" required fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPInput type="number" placeholder="Dauer" label="Dauer (Minuten)" value={pageState.duration} onChange={handleDurationChange} variant="standard" required fullWidth />
                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <PPBox mt={3} lineHeight={1}>
                                    <span>Arbeitszeit</span>
                                    <Switch checked={pageState.workingTime} onChange={handleWorkingTimeSwitch} />
                                    <div>
                                        <PPTypography variant="caption" color="text">
                                            {t('considerWorkTime')}
                                        </PPTypography>
                                    </div>
                                </PPBox>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                {
                                    pageState.workingTime ? <PPBox mt={3} lineHeight={1}>
                                        <span>Dokumentation aktivieren</span>
                                        <Switch checked={pageState.enableDocumentation} onChange={handleEnableDocumentationSwitch} />
                                        <div>
                                            <PPTypography variant="caption" color="text">
                                                {t('enableCategoryDocumentation')}
                                            </PPTypography>
                                        </div>
                                    </PPBox> : null
                                }

                            </Grid>
                        </Grid>
                    </PPBox>
                    <PPBox mb={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                {
                                    pageState.workingTime ? <PPBox mt={3} lineHeight={1}>
                                        <PPInput type="number" placeholder="Preis" label="Preis (€)" value={pageState.rate} onChange={handleRateChange} variant="standard" step="0.01" fullWidth />
                                        <div>
                                            <PPTypography variant="caption" color="text">
                                                {t('categoryPrice')}
                                            </PPTypography>
                                        </div>
                                    </PPBox> : null
                                }
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>

                            </Grid>
                        </Grid>
                    </PPBox>
                </form>
            </DialogContent>
            <DialogActions>
                <Divider />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="light" onClick={handleCloseEditor} fullWidth>
                            <Icon>cancel</Icon>&nbsp;Schließen
                        </PPButton>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        {
                            pageState.id !== null && pageState.id !== '' ?
                                <PPButton variant="gradient" color="error" onClick={handleDeleteClick} fullWidth>
                                    <Icon>delete</Icon>&nbsp;löschen
                                </PPButton> : null
                        }
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPButton variant="gradient" color="primary" type="submit" form="formManageCategory" fullWidth>
                            <Icon>save</Icon>&nbsp;Speichern
                        </PPButton>
                    </Grid>
                </Grid>
            </DialogActions>
            <ConfirmDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                message={`Sind Sie sicher, dass Sie diese Kategorie löschen möchten?`}
            />
        </Dialog>
    );
}

export default EventCategoryEditor;