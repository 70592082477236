import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import { appEndPoints } from "appSettings";
import { getCurrentUser, getRequestOptions } from "controls/Helpers";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import { TextField, InputAdornment } from '@mui/material';
import NoRecords from "controls/NoRecords";
import NoteCard from './components/NoteCard';
import NoteEditor from "./components/NoteEditor";
import ConfirmDialog from 'controls/Dialogs/ConfirmDialog';
import { setPageTitle } from "controls/Helpers";
import { useTranslation } from 'react-i18next';

function Notes() {
    const { t } = useTranslation();
    setPageTitle(t('notes'));
    const [pageState, setPageState] = useState({
        viewMode: "view",
        selectedNoteId: '',
        currentUser: getCurrentUser(),
        isLoading: false,
        data: [],
        totalRecords: 0,
        currentPage: 1,
        pageSize: 10,
        searchQuery: '',
    });
    const [dialogEditorOpen, setDialogEditorOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    //const [menu, setMenu] = useState(false);
    //const [photo, setPhoto] = useState(null);

    const handleOpenEditorDialog = (arg) => {
        setPageState(old => ({ ...old, selectedNoteId: '' }));
        setDialogEditorOpen(true);
    };

    const handleCloseEditorDialog = () => {
        setDialogEditorOpen(false);
    };

    const handleNoteEditClick = (arg) => {
        setPageState(old => ({ ...old, selectedNoteId: arg }));
        setDialogEditorOpen(true);
    };

    const handleNoteDeleteClick = (arg) => {
        setPageState(old => ({ ...old, selectedNoteId: arg }));
        setConfirmDialogOpen(true);
    };

    const handleConfirmDelete = () => {
        var body = JSON.stringify({
            "id": pageState.selectedNoteId,
        });
        var serviceUrl = appEndPoints.deleteNoteEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions('DELETE', body, 'json'))
            .then(resp => resp.json())
            .then(resp => {
                handleRefreshListing();
            })
            .catch(error => { console.log('error', error); })

        // Close the dialog after the action is performed
        setConfirmDialogOpen(false);
    };

    const handleSearchQueryChange = (e) => {
        e.preventDefault();
        setPageState(x => ({ ...x, searchQuery: e.target.value }));
    }

    const handleSearchClick = (e) => {
        e.preventDefault();
        setPageState(old => ({ ...old, currentPage: 1 }));
        fetchGridData(pageState.searchQuery);
    }

    const handleRefreshListing = () => {
        fetchGridData('');
    };

    const handleLoadMore = (e) => {
        e.preventDefault();
        setPageState(old => ({ ...old, currentPage: pageState.currentPage + 1 }))
    }

    function fetchGridData(q) {
        setPageState(old => ({ ...old, isLoading: true }));
        var fetchURL = appEndPoints.searchNotesEndpoint.replace("{hostname}", appEndPoints.hostname).replace("{pageNumber}", pageState.currentPage).replace("{pageSize}", pageState.pageSize) + "?q=" + q;
        fetch(fetchURL, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                let currentData = resp.notes;
                if (pageState.currentPage > 1) {
                    currentData = pageState.data;
                    resp.notes.map((e, index) => (
                        currentData.push(e)
                    ))
                }
                setPageState(old => ({ ...old, isLoading: false, data: currentData, totalRecords: resp.totalRecords }));
            })
            .catch(error => { console.log('error', error); setPageState(old => ({ ...old, isLoading: false })); })
    }

    useEffect(() => {
        fetchGridData('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState.currentPage, pageState.pageSize])

    return (
        <PrivateLayout>
            <Card>
                <PPBox p={2}>
                    <Grid container>
                        <Grid item xs={6} md={6} lg={6} textAlign="left">
                            <TextField
                                label="Notiz suchen" fullWidth
                                variant="outlined" autoComplete="false"
                                value={pageState.searchQuery} onChange={handleSearchQueryChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearchClick}>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={6} lg={6} textAlign="right">
                            <PPButton color="primary" onClick={handleOpenEditorDialog}>
                                <Icon>add_circle</Icon>&nbsp;
                                Hinzufügen
                            </PPButton>
                        </Grid>
                    </Grid>
                </PPBox>
                <PPBox pt={1} px={2}>
                    <NoteEditor noteId={pageState.selectedNoteId} open={dialogEditorOpen} handleCloseDialog={handleCloseEditorDialog} handleRefreshListing={handleRefreshListing} />
                    <PPBox pb={2} style={{ minHeight: "500px" }}>
                        <Grid container spacing={2}>
                            {
                                pageState.data.map((i, j) => (
                                    <NoteCard note={i} key={j}
                                        handleNoteEditClick={handleNoteEditClick}
                                        handleNoteDeleteClick={handleNoteDeleteClick}
                                    />
                                ))
                            }
                            {
                                (pageState.data === null || pageState.data.length === 0) && !pageState.isLoading ?
                                    <NoRecords />
                                    : null
                            }
                            {
                                ((pageState.currentPage) * pageState.pageSize >= pageState.totalRecords) ?
                                    null :
                                    <PPBox style={{ width: "100%", paddingTop: "40px", paddingLeft: "15px", textAlign: "center" }}>
                                        <PPButton variant="gradient" color="primary" type="button" onClick={handleLoadMore} disabled={pageState.isLoading}>
                                            {pageState.isLoading ? <CircularProgress size={15} style={{ marginRight: "10px" }} /> : null}
                                            Mehr laden
                                        </PPButton>
                                    </PPBox>
                            }
                            {
                                pageState.currentPage === 1 && pageState.isLoading ?
                                    <div style={{ width: "100%", textAlign: "center", marginTop: "20px", paddingTop: "30px" }}>
                                        <CircularProgress style={{ marginRight: "10px" }} />
                                    </div>
                                    : null
                            }
                            <ConfirmDialog
                                open={confirmDialogOpen}
                                onClose={() => setConfirmDialogOpen(false)}
                                onConfirm={handleConfirmDelete}
                                message={t("confirmDeleteNoteMsg")}
                            />
                        </Grid>
                    </PPBox>
                </PPBox>
            </Card>
        </PrivateLayout>
    );
}

export default Notes;
