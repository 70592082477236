import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import PPButton from "components/PPButton";
import Icon from "@mui/material/Icon";
import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import { appEndPoints } from "appSettings";
import moment from 'moment';
import { getCurrentUser, getRequestOptions, getUserStatusAsBadge, setPageTitle } from "controls/Helpers";
import Card from "@mui/material/Card";
import PPAvatar from "components/PPAvatar";
import DataTable from "controls/Tables/DataTable";
import UserEditor from "./components/UserEditor";
import WorkingHoursEditor from "./components/WorkingHoursEditor";
import Spinner from "controls/Spinner";
import { useTranslation } from 'react-i18next';

function Users() {
    const { t } = useTranslation();
    setPageTitle(t('users'));
    const [pageState, setPageState] = useState({
        viewMode: "view",
        selectedUserId: '',
        currentUser: getCurrentUser(),
        data: [],
        rows: []
    });
    const [dialogEditorOpen, setDialogEditorOpen] = useState(false);
    const [workingHoursEditorOpen, setWorkingHoursEditorOpen] = useState(false);
    const [openSpinnerPanel, setOpenSpinnerPanel] = useState(false);

    const handleEditClick = (arg) => {
        setPageState(old => ({ ...old, selectedUserId: arg }));
        setDialogEditorOpen(true);
    };

    const handleWorkingHoursEditClick = (arg) => {
        setPageState(old => ({ ...old, selectedUserId: arg }));
        setWorkingHoursEditorOpen(true);
    };

    const handleOpenEditorDialog = (arg) => {
        setPageState(old => ({ ...old, selectedUserId: '' }));
        setDialogEditorOpen(true);
    };

    const columns = [
        { Header: "Benutzer", accessor: "user", width: "45%", align: "left" },
        { Header: "Beitrittsdatum", accessor: "joiningDate", align: "center" },
        { Header: "Geburtsdatum", accessor: "birthdate", align: "center" },
        { Header: "Status", accessor: "status", align: "center" },
        { Header: "Aktion", accessor: "action", align: "center" },
    ];

    const fetchUserPhoto = async (user) => {
        if (user.photoFileName) {
            const fetchImageURL = appEndPoints.getFileBinary
                .replace("{hostname}", appEndPoints.hostname)
                .replace("{id}", user.id)
                .replace("{fileName}", user.photoFileName);
            try {
                const response = await fetch(fetchImageURL, getRequestOptions());
                if (!response.ok) {
                    throw new Error('Error occurred');
                }
                const blob = await response.blob();
                const blobURL = URL.createObjectURL(blob).toString();
                return blobURL || '';
            } catch (error) {
                console.error('Fetch error:', error);
                return '';
            }
        }
        return '';
    };

    const User = ({ image, name, email }) => (
        <PPBox display="flex" alignItems="center" lineHeight={1}>
            <PPAvatar src={image} name={name} size="sm" bgColor="light" shadow="md" />
            <PPBox ml={2} lineHeight={1}>
                <PPTypography display="block" variant="button" fontWeight="medium">
                    {name}
                </PPTypography>
                <PPTypography variant="caption">{email}</PPTypography>
            </PPBox>
        </PPBox>
    );

    let rows =
        pageState.data.map((e, index) => ({
            user: <User image={e.image} name={e.firstName + ' ' + e.lastName} email={e.email} />,
            joiningDate: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {e.joiningDate ? moment(e.joiningDate, "YYYY-MM-DD").format("DD.MM.YYYY") : "-"}
                </PPTypography>
            ),
            birthdate: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {e.birthdate ? moment(e.birthdate, "YYYY-MM-DD").format("DD.MM.YYYY") : "-"}
                </PPTypography>
            ),
            status: (
                <PPTypography component="span" variant="caption" color="text" fontWeight="medium">
                    {getUserStatusAsBadge(e.status)}
                </PPTypography>
            ),
            action: (
                <div>
                    <PPTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <PPButton variant="outlined" color="primary" size="small" onClick={() => handleWorkingHoursEditClick(e.id)}>
                            <Icon>work_history</Icon>&nbsp;Arbeitszeit
                        </PPButton>
                    </PPTypography>
                    &nbsp;
                    <PPTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <PPButton variant="outlined" color="primary" size="small" onClick={() => handleEditClick(e.id)}>
                            <Icon>edit</Icon>&nbsp;bearbeiten
                        </PPButton>
                    </PPTypography>
                </div>
            ),
        }));

    const handleCloseEditorDialog = () => {
        setDialogEditorOpen(false);
    };

    const handleCloseWorkingHoursEditorDialog = () => {
        setWorkingHoursEditorOpen(false);
    };

    const handleRefreshListing = () => {
        fetchGridData();
    };

    const fetchGridData = async () => {
        setOpenSpinnerPanel(true);
        try {
            const fetchURL = appEndPoints.getUsersEndpoint.replace("{hostname}", appEndPoints.hostname);
            const response = await fetch(fetchURL, getRequestOptions());
            const data = await response.json();
            setOpenSpinnerPanel(false);
            setPageState((old) => ({ ...old, data: data }));
            // Fetch photos for each user in parallel
            await Promise.all(data.map(async (e) => {
                e.image = await fetchUserPhoto(e);
            }));
            setPageState((old) => ({ ...old, data: data }));
        } catch (error) {
            setOpenSpinnerPanel(false);
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchGridData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PrivateLayout>
            <Card>
                <PPBox pt={0} pb={3}>
                    <PPBox p={2}>
                        <Grid container>
                            <Grid item xs={12} md={6} lg={6} textAlign="left">
                            </Grid>
                            <Grid item xs={12} md={6} lg={6} textAlign="right">
                                <PPButton color="primary" onClick={handleOpenEditorDialog}>
                                    <Icon>add_circle</Icon>&nbsp;
                                    Einladen
                                </PPButton>
                            </Grid>
                        </Grid>
                    </PPBox>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <PPBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    noEndBorder
                                    handleEditClick={handleEditClick}
                                />
                                {
                                    dialogEditorOpen ? <UserEditor userId={pageState.selectedUserId} open={dialogEditorOpen} handleCloseDialog={handleCloseEditorDialog} handleRefreshListing={handleRefreshListing} /> : null
                                }
                                {
                                    workingHoursEditorOpen ? <WorkingHoursEditor userId={pageState.selectedUserId} open={workingHoursEditorOpen} handleCloseDialog={handleCloseWorkingHoursEditorDialog} /> : null
                                }
                            </PPBox>
                        </Grid>
                    </Grid>
                </PPBox>
            </Card>
            <Spinner open={openSpinnerPanel} />
        </PrivateLayout>
    );
}

export default Users;
