import PrivateLayout from "controls/LayoutContainers/PrivateLayout";
import Card from "@mui/material/Card";
import { setPageTitle } from "controls/Helpers";
import { useTranslation } from 'react-i18next';

function Inquiries() {
    const { t } = useTranslation();
    setPageTitle(t('inquiries'));
    return (
        <PrivateLayout>
            <Card>
                
            </Card>
        </PrivateLayout>
    );
}

export default Inquiries;
