import { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import PPBox from "components/PPBox";
import ReportsLineChart from "controls/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "controls/Cards/StatisticsCards/ComplexStatisticsCard";
import {
    Select,
    MenuItem,
    Icon,
} from '@mui/material';
import PPButton from "components/PPButton";
import moment from "moment";
import { appEndPoints } from "appSettings";
import { getRequestOptions, getCurrentUserId, getCurrentUser, userHasAccess, ROLES } from "controls/Helpers";
import NotesPreview from 'pages/notes/components/NotesPreview';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const EmployeeOverview = () => {
    const { t } = useTranslation();
    const currentDate = moment();
    const currentMonth = currentDate.month() + 1; // 0-based index
    const currentYear = currentDate.year();
    const [selectedUserId, setSelectedUserId] = useState(getCurrentUserId());
    const [users, setUsers] = useState([]);
    const [years, setYears] = useState([]);
    const allMonths = Array.from({ length: 12 }, (_, i) => {
        const date = new Date(2023, i, 1); // Create a date for each month
        const monthName = date.toLocaleString('de', { month: 'long' });
        return { value: i + 1, label: monthName };
    });

    const monthsUntilNow = Array.from({ length: currentMonth }, (_, i) => {
        const date = new Date(2023, i, 1); // Create a date for each month
        const monthName = date.toLocaleString('de', { month: 'long' });
        return { value: i + 1, label: monthName };
    });

    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);

    const [monthlyTimeTracking, setMonthlyTimeTracking] = useState({
        actualWorkingHours: 0,
        overallActualWorkingHours: 0,
        expectedWorkingHours: 0,
        vacationDays: 0,
        plannedApprovedVacationDays: 0,
        plannedPendingVacationDays: 0,
        remainingVacationDays: 0,
        sickLeave: 0
    });

    const [yearlyTimeTracking, setYearlyTimeTracking] = useState({
        actualWorkingHours: 0,
        overallActualWorkingHours: 0,
        expectedWorkingHours: 0,
        vacationDays: 0,
        plannedApprovedVacationDays: 0,
        plannedPendingVacationDays: 0,
        remainingVacationDays: 0,
        sickLeave: 0
    });
    const [yearlyAllTimeTracking, setYearlyAllTimeTracking] = useState({
        labels: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        datasets: { label: "Hours", data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
    });

    const handleUserSelectionChange = (e) => {
        setSelectedUserId(e.target.value);
    }

    const handleMonthSelectionChange = (e) => {
        setSelectedMonth(e.target.value);
    }

    const handleYearSelectionChange = (e) => {
        setSelectedYear(e.target.value);
    }

    const handleFilterClick = (e) => {
        e.preventDefault();
        filterDashboard();
    }

    function fetchYears() {
        var serviceUrl = appEndPoints.getTimeTrackingYearsEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                var elements = [];
                for (var i = 0; i < resp.length; i++) {
                    elements.push({
                        value: resp[i],
                        label: resp[i],
                    });
                }
                setYears(elements);
            })
    }

    function fetchUsers() {
        var serviceUrl = appEndPoints.getUsersEndpoint.replace("{hostname}", appEndPoints.hostname);
        fetch(serviceUrl, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                var elements = [];
                for (var i = 0; i < resp.length; i++) {
                    elements.push({
                        value: resp[i].id,
                        label: resp[i].firstName + ' ' + resp[i].lastName,
                    });
                }
                setUsers(elements);
            })
    }

    function filterDashboard() {
        var serviceUrl = appEndPoints.getTimeTrackingByMonthEndpoint.replace("{hostname}", appEndPoints.hostname).replace("{userId}", selectedUserId).replace("{month}", selectedMonth).replace("{year}", selectedYear);
        fetch(serviceUrl, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                setMonthlyTimeTracking(old => ({
                    ...old, actualWorkingHours: resp.actualWorkingHours,
                    overallActualWorkingHours: resp.overallActualWorkingHours,
                    expectedWorkingHours: resp.expectedWorkingHours,
                    vacationDays: resp.vacationDays,
                    remainingVacationDays: resp.remainingVacationDays,
                    sickLeave: resp.sickLeave
                }));
            })
        serviceUrl = appEndPoints.getTimeTrackingByYearEndpoint.replace("{hostname}", appEndPoints.hostname).replace("{userId}", selectedUserId).replace("{year}", selectedYear);
        fetch(serviceUrl, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                setYearlyTimeTracking(old => ({
                    ...old,
                    actualWorkingHours: resp.actualWorkingHours,
                    overallActualWorkingHours: resp.overallActualWorkingHours,
                    expectedWorkingHours: resp.expectedWorkingHours,
                    vacationDays: resp.vacationDays,
                    remainingVacationDays: resp.remainingVacationDays,
                    sickLeave: resp.sickLeave
                }));
            })
        serviceUrl = appEndPoints.getTimeTrackingByYearAllEndpoint.replace("{hostname}", appEndPoints.hostname).replace("{userId}", selectedUserId).replace("{year}", selectedYear);
        fetch(serviceUrl, getRequestOptions())
            .then(resp => resp.json())
            .then(respData => {
                //setYearlyAllTimeTracking();
                const labels = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];

                const datasets = {
                    label: "Hours",
                    data: monthsUntilNow.map((month) => {
                        // Find the corresponding item in respData based on the label
                        const matchingItem = respData.find((item) => item.month === month.value);
                        // If a matching item is found, extract the data; otherwise, set it to 0
                        return matchingItem ? matchingItem.overallActualWorkingHours : 0;
                    }),
                };
                const result = {
                    labels,
                    datasets: datasets,
                };
                setYearlyAllTimeTracking(result);
            })

        serviceUrl = appEndPoints.leaveRequestsEndpoint.replace("{hostname}", appEndPoints.hostname) + "/planned/" + selectedUserId;
        fetch(serviceUrl, getRequestOptions())
            .then(resp => resp.json())
            .then(resp => {
                if (resp && Array.isArray(resp)) {
                    let totalPendingDuration = 0;
                    let totalApprovedDuration = 0;
                    
                    resp.forEach(currentValue => {
                        if (currentValue.status === 0) { // Pending
                            totalPendingDuration += currentValue.duration;
                        } else if (currentValue.status === 1) { // Approved
                            totalApprovedDuration += currentValue.duration;
                        }
                    });
                    setYearlyTimeTracking(old => ({ ...old, plannedApprovedVacationDays: totalApprovedDuration }));
                    setYearlyTimeTracking(old => ({ ...old, plannedPendingVacationDays: totalPendingDuration }));
                }
            })
    }

    useEffect(() => {
        fetchUsers();
        fetchYears();
        filterDashboard();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PPBox>
            <PPBox pb={4}>
                <Grid container spacing={2} textAlign="left" alignItems="center">
                    {
                        userHasAccess(ROLES.CALENDAR_VIEWER, getCurrentUser().roles) ?
                            <Grid item xs={12} sm={12} md={6} lg={3}>
                                {t("employee")}:
                                <Select value={selectedUserId} onChange={handleUserSelectionChange} className='selectList' sx={{ m: 1, minWidth: 160, maxHeight: 35 }}>
                                    {users.map(item => {
                                        return (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>);
                                    })}
                                </Select>
                            </Grid> : null
                    }
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        {t("month")}: <Select value={selectedMonth} onChange={handleMonthSelectionChange} className='selectList' sx={{ m: 1, minWidth: 150, maxHeight: 35 }}>
                            {allMonths.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        {t("year")}: <Select value={selectedYear} onChange={handleYearSelectionChange} className='selectList' sx={{ m: 1, minWidth: 90, maxHeight: 35 }}>
                            {years.map((year) => (
                                <MenuItem key={year.value} value={year.value}>
                                    {year.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2}>
                        <PPButton variant="gradient" color="primary" onClick={handleFilterClick}>
                            <Icon>check</Icon>&nbsp;Anwenden
                        </PPButton>
                    </Grid>
                </Grid>
            </PPBox>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                    <PPBox mb={1.5} borderRadius="lg" coloredShadow="light">
                        <ComplexStatisticsCard
                            icon="luggage"
                            title={t("remainingVacationDays")}
                            count={yearlyTimeTracking.remainingVacationDays}
                            unitOfMeasure={t("day(s)")}
                            percentage={{
                                color: "success",
                                amount: yearlyTimeTracking.vacationDays,
                                label: t("daysTakenThisYear"),
                            }}
                        />
                    </PPBox>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <PPBox mb={1.5} borderRadius="lg" coloredShadow="light">
                        <ComplexStatisticsCard
                            color="warning"
                            icon="luggage"
                            title={t("plannedVacationDays")}
                            count={yearlyTimeTracking.plannedApprovedVacationDays}
                            unitOfMeasure={t("daysApproved")}
                            percentage={{
                                color: "success",
                                amount: yearlyTimeTracking.plannedPendingVacationDays,
                                label: t("daysPending"),
                            }}
                        />
                    </PPBox>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <PPBox mb={1.5} borderRadius="lg" coloredShadow="light">
                        <ComplexStatisticsCard
                            color="dark"
                            icon="sick"
                            title={t("sickDaysTaken")}
                            count={monthlyTimeTracking.sickLeave}
                            unitOfMeasure={t("day(s)")}
                            percentage={{
                                color: "success",
                                amount: yearlyTimeTracking.sickLeave,
                                label: t("daysTakenThisYear"),
                            }}
                        />
                    </PPBox>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <PPBox mb={1.5} borderRadius="lg" coloredShadow="light">
                        <ComplexStatisticsCard
                            color="success"
                            icon="leaderboard"
                            title={t("overallWorkingHours")}
                            count={monthlyTimeTracking.overallActualWorkingHours}
                            unitOfMeasure={t("hour(s)")}
                            percentage={{
                                color: "success",
                                amount: monthlyTimeTracking.actualWorkingHours,
                                label: t("hoursWorkedThisMonth"),
                            }}
                        />
                    </PPBox>
                </Grid>
            </Grid>
            <PPBox mt={4.5}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8} lg={8}>
                        <PPBox mb={3} borderRadius="lg" coloredShadow="light">
                            <ReportsLineChart
                                color="dark"
                                title="Monatliche Leistung"
                                description="Gesamtarbeitsstunden pro Monat"
                                chart={yearlyAllTimeTracking}
                            />
                        </PPBox>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <PPBox mb={3} borderRadius="lg" coloredShadow="light">
                            <NotesPreview />
                        </PPBox>
                    </Grid>
                </Grid>
            </PPBox>
        </PPBox>
    );
}

export default EmployeeOverview;
