
import { useMemo } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import configs from "controls/Charts/LineCharts/ReportsLineChart/configs";

function ReportsLineChart({ color, title, description, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  return (
    <Card sx={{ height: "100%" }}>
      <PPBox padding="1rem">
        <PPBox pb={6} px={1}>
          <PPTypography variant="h6" textTransform="capitalize">
            {title}
          </PPTypography>
          <PPTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </PPTypography>
        </PPBox>
        {useMemo(
          () => (
            <PPBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="20.5rem"
            >
              <Line data={data} options={options} />
            </PPBox>
          ),
          [color, data, options]
        )}
      </PPBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsLineChart;
