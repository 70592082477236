import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import PPBox from "components/PPBox";
import { usePraxiProUIController, setLayout } from "context";
import { isAuthenticated, currentUserHasAccessToPage } from "controls/Helpers";
import Footer from "controls/Footer";
import AccessDenied from "controls/AccessDenied";

function PrivateLayout({ children }) {
  const [controller, dispatch] = usePraxiProUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <PPBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(250),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {isAuthenticated() ?
        <div className="mainContainer">
          {
            currentUserHasAccessToPage(pathname) ? children : <AccessDenied />
          }
        </div>
        :
        <Navigate to="/sign-in" />
      }
      <Footer />
    </PPBox>
  );
}

// Typechecking props for the PrivateLayout
PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateLayout;