import { useState } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import PPBox from "components/PPBox";
import PPTypography from "components/PPTypography";
import typography from "assets/theme/base/typography";
import PrivacyPolicy from "controls/Legal/PrivacyPolicy";
import TermsOfService from "controls/Legal/TermsOfService";
import { useTranslation } from 'react-i18next';

function Footer({ company, links }) {
  const { t } = useTranslation();
  //const { href, name } = company;
  const { size } = typography;
  const [dialogPrivacyPolicyOpen, setDialogPrivacyPolicyOpen] = useState(false);
  const [dialogTermsOfServiceOpen, setDialogTermsOfServiceOpen] = useState(false);


  const handleOpenPrivacyPolicyDialog = () => {
    setDialogPrivacyPolicyOpen(true);
  }

  const handleClosePrivacyPolicyDialog = () => {
    setDialogPrivacyPolicyOpen(false);
  };

  const handleOpenTermsOfServiceDialog = () => {
    setDialogTermsOfServiceOpen(true);
  }

  const handleCloseTermsOfServiceDialog = () => {
    setDialogTermsOfServiceOpen(false);
  };

  return (
    <PPBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      p={2}
    >
      <PPBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, PraxiPro
      </PPBox>
      <PPBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        <PPBox component="li" px={2} lineHeight={1}>
          <Link href="#" onClick={handleOpenPrivacyPolicyDialog}>
            <PPTypography variant="button" fontWeight="regular" color="text">
              {t("privacyPolicy")}
            </PPTypography>
          </Link>
          <PrivacyPolicy open={dialogPrivacyPolicyOpen} handleCloseDialog={handleClosePrivacyPolicyDialog} />
        </PPBox>
        <PPBox component="li" px={2} lineHeight={1}>
          <Link href="#" onClick={handleOpenTermsOfServiceDialog}>
            <PPTypography variant="button" fontWeight="regular" color="text">
              {t("termsOfService")}
            </PPTypography>
          </Link>
          <TermsOfService open={dialogTermsOfServiceOpen} handleCloseDialog={handleCloseTermsOfServiceDialog} />
        </PPBox>
      </PPBox>
    </PPBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "#", name: "PraxiPro" },
  links: [
    // { href: "#", name: "About Us" },
    // { href: "#", name: "Blog" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
